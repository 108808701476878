export const environment = {
	production: true,
	enableBooking : true,
	allowCancellation : true,
	name: 'stage',
	url: 'https://stage.aadeshtrips.com/air',
	account: 'https://stage.aadeshtrips.com/account',
	accountV2: 'https://stage.aadeshtrips.com/account', 
	booking: 'https://stage.aadeshtrips.com/booking',
	adminUrl: 'https://stage.aadeshtrips.com/a/api/v2/legacy',
	excelAccountingUrl: 'https://stage.aadeshtrips.com/edge/excel',
	webSocketUrl: "wss://stage.aadeshtrips.com/events/ws/signal",
	redisCommission: 'https://stage.aadeshtrips.com/edge/redis-commission-api',  //redis-commission
	smsUrl: 'https://alerts.icisms.in/api/web2sms.php?workingkey=A9650f4f017892129d6a4dacf9e5f047d&sender=SKYFLY&',
	ftCashPaymentUrl : 'https://stage.aadeshtrips.com/edge/ft-cash',
	hdfcPaymentUrl : 'https://stage.aadeshtrips.com/edge/hdfc-pg', //Stage URL
	// hdfcPaymentUrl : 'https://aadeshtravels.com/edge/hdfc-pg', // Prod URL
	heimdallUrl : 'wss://stage.aadeshtrips.com/edge-ws/',
	pdfGeneratorUrl : 'https://stage.aadeshtrips.com/puppet/pdfGenerator',
	ticketUrl : 'https://stage.aadeshtrips.com/#/template/print-ticket;searchId=',
	hotelUrl : 'https://stage.aadeshtrips.com/hotel/index.html?v=3.0',
	carUrl : 'https://stage.aadeshtrips.com/car/#/',
	carMarkup : 'https://stage.aadeshtrips.com/car/cars/v1.0',
	insuranceUrl : 'https://stage.aadeshtrips.com/insurance/index.html?v=3.0',
	parksUrl : 'https://stage.aadeshtrips.com/parks/index.html?v=3.0',
	visaUrl : 'https://stage.aadeshtrips.com/visa/',
	baseUrl : 'https://stage.aadeshtrips.com',
	apif_booking: 'https://apif.opskube.com/stage/apif-booking',
	apif_url : 'https://apif.opskube.com/stage',
	apif_account : 'https://apif.opskube.com/stage/apif-account',
	accountUrl : 'https://app.stage.systacc.com/api/v1',
	v3accountUrl : 'https://app.stage.systacc.com/api/v1',
	okountWebAppUrl : 'https://app.stage.systacc.com',
	controlPlaneUrl:'https://c.aadeshtrips.com/api',
	centri_token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0MiIsImluZm8iOnsibmFtZSI6IkhhcmlzaCBTaGFybWEifX0.A1eq49uI585uqJEGrBDV70JxgvHQ87SoV2NZ5MzkM80',
	centri_ws : 'wss://aadeshtravels.com/centrifugo-ws/websocket',
	notisUrl : 'https://stage.aadeshtrips.com/edge/notis-api/delivery',
	normalUrl:'https://stage.aadeshtrips.com',
	gateKeeperUrl:'https://stage.aadeshtrips.com/edge/gate-keeper',
	pnr_retrieve: 'https://stage.aadeshtrips.com/edge',
	v3Url:'https://stage.aadeshtrips.com/',
	digit_url:'https://digit-ifs.aadeshtrips.com',
	reCaptchaSiteKey : "6Le4HrgqAAAAAFxwsmlfV2WBxRNeKwBjdqbsMm4N",
	payment_gateway_charges : 2.5,
	firebase: {
		apiKey: "AIzaSyBNM-scZ-lm_TxuEenhJ1nrd3K_cQxMm88",
		authDomain: "test-51957.firebaseapp.com",
		databaseURL: "https://test-51957.firebaseio.com",
		projectId: "test-51957",
		storageBucket: "test-51957.appspot.com",
		messagingSenderId: "1063233930220"
	},

	payment : "https://payment.aadeshtravels.com/hostedhttp/HostedPaymentBuyHttp.php",
	supplier_code : {
		"SG" : "SG050",
		"6E" : "6E670",
		"G8" : "GO008",
		"AI" : "AI098",
		"UK" : "UK228",
		"2T" : "2T100",
		"TG" : "TG217",
	},
	crs_id : {
		"AMADEUS_V4": 'AM',
		"2T": '2T',
		"6E": '6E',
		"SG": 'SG',
		"SG_CORPORATE": 'SG',
		"SG_COUPON": 'SG',
		"GO_CORPORATE": 'G8',
		"GO_SPECIAL": 'G8',
		"GOAIR": 'G8',
		"SABRE": 'SB',
		"GALILEO": '1G'
	}
};
