

<div class="page-loader" [ngClass]="active ? 'd-block' : 'd-none'">
    <div class="loader">
      <div
      class=" text-center" >
      <div class="img-container">
        <img
          src="../assets/v3images/animated-rocket.gif"
          alt=""
        />
      </div>
      <div
        class="animated-loader-text d-flex align-items-center justify-content-center"
      >
        <p class="text-white text-center" ><span class="text-bold d-block">Please Wait!</span><small>we are processsing data </small></p>
      </div>
    </div>
    </div>
  </div>


  <ng-container *ngIf="bookingArr.length > 0">
   
    <div class="alert alert-warning" role="alert"
        *ngIf="showHoldTTL ">
        Your Booking is on <strong>Hold</strong> and it will be cancelled automatically after 
            <strong>{{bookingArr[0].json_doc.supplierInfo.hold_ttl | dateFormat}}  {{ bookingArr[0].json_doc.supplierInfo.hold_ttl | timeFormat }}</strong>.
    </div>
    <div class="alert alert-danger" role="alert"
        *ngIf="holdTimelineMessage && showHoldTTL" [innerHTML]="holdTimelineMessage">
    </div>

    <div class="alert alert-danger" role="alert"
        *ngIf="showError == true" [innerHtml]="errorMessage">
    </div>

    <div class="alert alert-danger" role="alert"
        *ngIf="ssrFailMessage" [innerHtml]="ssrFailMessage">
    </div>
    <a class="ml-2 mb-1 text-grey transition hover-text-grey mt-2" href="javascript:void(0)" routerLink="/bookingsummary"><i class="fas fa-arrow-left mr-2"></i>Back</a>
    <div class="booking-imp-info">
  
        <!-- <div class="address float-left">
            <div class="c-name text-pinkish-red">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.companyName}}</div>
            <div  *ngIf="bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.mobile != undefind" class="phone"><small class="text-bold text-grey mt-3 d-block">Phone No.:</small> <span class="font-weight-bold">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.mobile}}</span></div>
            <small class="text-bold text-grey d-block mt-3">Address:</small>
            <div class="c-address text-uppercase">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.address1}}</div>
            <div class="c-address">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.city}}, {{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.stateCode}}</div>
        </div> -->

       <div class="card">
        <div class="row m-0  " >
            <div class="col-4 pl-0">
            <div class=" p-2">
             <div class="book-refno"><span>Booking Ref. No. :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr[0]?.json_doc.booking_ref}} <i title="Copy Booking Ref. No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0]?.json_doc.booking_ref,'Booking Ref')"></i></span></div>
 
             <div class="book-refno" *ngIf="bookingArr[0].json_doc.api_info != undefined && bookingArr[0]?.json_doc?.api_info != null"><span>My Ref. No. :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr[0]?.json_doc?.api_info?.affiliate_booking_info?.booking_ref_no}} <i title="Copy Booking Ref. No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0]?.json_doc?.api_info?.affiliate_booking_info?.booking_ref_no,'Booking Ref')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'FAILED' && bookingArr[0].json_doc.pnr_status != 'HOLD'"><span>PNR : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].pnr}} <i title="Copy PNR" class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc.segments[0].pnr,'PNR')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED' && bookingArr[0].json_doc.invoice_no"><span>CRN No. : </span><span class="font-weight-bold float-right">IZ{{getYear()}}-{{bookingArr[0].json_doc?.invoice_no}} <i title="Copy CRN No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage('IZ'+getYear()+'-'+bookingArr[0].json_doc?.invoice_no,'CRN No.')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED' && bookingArr[0].json_doc.pnr_status != 'FAILED' "><span>Invoice No. : </span><span class="font-weight-bold float-right">IW{{getYear()}}-{{bookingArr[0].json_doc?.invoice_no}} <i title="Copy Invoice No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage('IW'+getYear()+'-'+bookingArr[0].json_doc?.invoice_no,'Invoice No.')"></i></span></div>
            </div>
            </div>
 
          <div class="col-4">
             <div class=" p-2 pl-3 pr-3">
                 <!-- <div class="book-refno"><span>Fare Type :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr && bookingArr[0].json_doc.displayPriceType?bookingArr[0].json_doc.displayPriceType:bookingArr[0]?.json_doc.fare_type}}</span></div> -->
                 <div class="book-refno"><span>Fare Type :&nbsp;</span><span class="font-weight-bold float-right"><img    src="assets/v3images/icons/purple-student-cap.svg" alt="STUD" *ngIf="bookingArr[0].json_doc.supplierInfo.subPriceType =='STUD'" />
                    <img class="w-25 m-auto" *ngIf="bookingArr[0].json_doc.supplierInfo.subPriceType =='SRCT'"  src="assets/v3images/icons/purple-senior-citizen.svg" alt="SRCT" />{{bookingArr && bookingArr[0].json_doc.displayPriceType?bookingArr[0].json_doc.displayPriceType:bookingArr[0]?.json_doc.fare_type}} </span></div>    
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED' && bookingArr[0].json_doc.pnr_status != 'FAILED' && (bookingArr[0].json_doc.api_info != undefined && bookingArr[0]?.json_doc?.api_info != null)"><span>My Invoice No. : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc?.api_info?.affiliate_booking_info?.invoice_no}}</span></div>
             <div class="pnr-status">
                 <span>Status : </span>
                 <span class="font-weight-bold float-right">
                     <ng-container *ngIf="bookingArr[0].json_doc?.pnr_status != 'FAILED'">
                         <span class="text-success">{{(bookingArr[0].json_doc?.pnr_status)}}</span>
                     </ng-container>
                     <ng-container *ngIf="bookingArr[0].json_doc?.pnr_status == 'FAILED'">
                         <ng-container *ngIf="bookingArr[0].json_doc.supplierInfo && bookingArr[0].json_doc.supplierInfo.groupFareInfo == undefined">
                             <span class="text-danger">{{(bookingArr[0].json_doc?.pnr_status)}}</span>
                         </ng-container>
                         <ng-container *ngIf="bookingArr[0].json_doc.supplierInfo && bookingArr[0].json_doc.supplierInfo.groupFareInfo">
                             <span class="text-info">IN-PROGRESS</span>
                         </ng-container>
                     </ng-container>
                 </span>
             </div>
             </div>
          </div>
         <div class="col-4 pr-0">
                <div class=" p-2">
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.booking_hold_date != undefined"><span>Hold Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.booking_hold_date | dateFormat}}</span></div>
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD'"><span>Booked Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.booked_timestamp | dateFormat}}</span></div>
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED'"><span>Cancelled Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.cancelled_timestamp | dateFormat}}</span></div>
                     <!-- <div class="travel-date"><span>Ticket Date : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp }}</span></div> -->
                     <div class="travel-date"><span>Travel Date : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp | dateFormat}}</span></div>
                     </div>
                </div>
         </div>
       </div>
    </div>
    <div class="alert alert-danger" *ngIf="bookingArr[0].json_doc.pnr_status == 'FAILED'">
        <span>Failed to ticket this PNR, No worries experts for our office will help you in getting it done. Please feel free to get connected! </span>
    </div>
    <!-- <div class="booking-details mb-2">
        <div class="head-style">
            <span class="b-head float-left">Booking Details</span>
            <span class="b-link float-right"><i class="fa fa-reply" aria-hidden="true"></i><a class="theme-link" href="javascript:void(0)" routerLink="/bookingsummary">Back to
							Ticket Queue</a></span>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div> -->

    <div class="flight-details card mb-2">
        <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
            <p class="text-bold text-dark-green m-0  ">Flight Information</p>

        </div>
            <div class="custom-table">
                <table class="table flight-info-tbl">
                    <thead>
                        <tr>
                            <th style="width: 90px;" class="text-left">Flight No</th>
                            <th style="width: 60px;" class="text-left">Origin</th>
                            <th style="width: 90px;" class="text-left">Destination</th>
                            <th style="width: 180px;" class="text-left">Departure</th>
                            <th style="width: 180px;" class="text-left">Arrival</th>
                            <th style="width: 60px;" class="text-left">Class</th>
                            <th style="width: 90px;" class="text-left">Baggage</th>
                            <th style="width: 90px;" class="text-right">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let segment of bookingArr[selectedFlightDataIndex].json_doc.segments; let s=index">
                            <tr *ngFor="let leg of segment.legs; let l=index" style="border-bottom: 1px solid #fff; border-right:5px solid #fff;background-color: #ede9ff;">
                                <td style="width: 90px;" class="text-left">{{leg.operating_airline_code}} - {{leg.flight_number}}</td>
                                <td style="width: 60px;" class="text-left">{{leg.origin_airport_code}}</td>
                                <td style="width: 90px;" class="text-left">{{leg.destination_airport_code}}</td>
                                <td style="width: 180px;" class="text-left">{{leg.origin_timestamp | dateFormat }} {{ leg.origin_timestamp | timeFormat }}</td>
                                <td style="width: 180px;" class="text-left">{{leg.destination_timestamp | dateFormat }} {{ leg.destination_timestamp | timeFormat }}</td>
                                <td style="width: 60px;" class="text-left">{{leg.cabin_class}}</td>
                                <td style="width: 90px;" class="text-left">{{leg?.freeCheckInBaggage?.unit}}&nbsp;{{leg?.freeCheckInBaggage?.unitTypeName}}</td>
                                <td style="width: 90px;" class="text-right text-bold .text-success" style="color: #28a745 !important;">
                                    {{getSectorStatus(s)}}
                                    <!-- <span *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED'">CONFIRMED</span>
                                    <span *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED'">CANCELLED</span> -->
                                </td>
                                <!-- FIX ME -->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
    </div>
        <div class="passenger-section card mb-3 ">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row  ">
                <p class="text-bold text-dark-green m-0">Passenger Details</p>
                <div class="text-center" *ngIf="!bookingArr[0].isPassportAdded">
                    <span class="text-bold text-dark-green m-0">Add Passport Details </span><i class="fa fa-solid fa-plus-circle" style="color: #8a2be2;font-size: 14px;" (click)="openTravellersFormModal()"></i>
                </div>
            </div>
          
            <div class="passenger-box card-body pt-3 p-0 clearfix row ml-0 mr-0">
                <div class="passenger  col-lg-6  mb-3" *ngFor="let traveller of bookingArr[0].json_doc.travellers; let i=index;">
                    <div class="card">
                    <div class="pt-2 pl-3 pr-3 pb-2">
                        <span class="sno float-left font-weight-bold">Passenger {{i+1}} • </span>
                        <span class="p-type float-left font-weight-bold">&nbsp;
									<ng-container *ngIf="traveller.type == 'ADT'">(Adult)</ng-container>
									<ng-container *ngIf="traveller.type == 'CHD'">(Child)</ng-container>
									<ng-container *ngIf="traveller.type == 'INF'">(Infant)</ng-container>
								</span>
                        <span class="p-link float-right">
									<i class="fa fa-ticket" aria-hidden="true"  *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'TICKETED'"></i>
									<a class="text-success text-bold" href="javascript:void(0)" (click)="printTicket()"  *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'TICKETED'"><small>View Ticket</small></a>
									<a class="text-danger text-bold" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'CANCELLED'"><small>CANCELLED</small></a>
									<a class="text-danger text-bold" href="javascript:void(0)"   *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED' && traveller.status == 'CANCELLED'"><small>CANCELLED</small></a>
								</span>
                        <div class="clearfix"></div>
                    </div>
                    <div class="card-body p-2 pb-3 pl-3">
                        <div>
                            <span class="text-grey text-bold mr-2">
                                Name:
                            </span>
                            {{traveller.title}} {{traveller.first_name}} {{traveller.last_name}}
                        </div>
                        <div>
                            <span class="text-grey text-bold mr-2">
                                Ticket <i class="fa fa-hashtag ticketnum" aria-hidden="true"></i>
                            </span>
                            {{traveller?.ticket?.number}}
                            <span *ngIf="bookedWithAffiliateCreds && traveller.ticket == null && (bookingArr[0].json_doc.pnr_status == 'FAILED' || bookingArr[0].json_doc.pnr_status == 'HOLD')">
                                <input id="my-input" class="form-control ticketInput" oninput="this.value = this.value.toUpperCase()"  type="text" name="">
                            </span>
                        </div>
                        <div *ngIf="traveller?.passport?.number">
                            <span class="text-grey text-bold mr-2">
                                Passport <i class="fa fa-hashtag ticketnum" aria-hidden="true"></i>
                            </span>
                            {{traveller?.passport?.number}}
                        </div>
                        <ng-container *ngIf="traveller?.ssr != null && traveller?.ssr !=  ''">
                            <div class="ssrInfoTravellerWise" id="traveller_{{i}}">
                                <ng-container *ngFor="let ssr of traveller.ssr">
                                    <hr>
                                      <ng-container *ngIf="ssr.seats != null && ssr.seats != '' && ssr.seats.name && ssr.seats.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Seat:
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :  
                                              <span title="{{ssr?.seats?.code?.code}} {{ssr?.seats?.code?.name}} ">
                                                  {{start_and_end(ssr?.seats?.code?.code ? ssr?.seats?.code?.code : ssr?.seats?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.seats.status && ssr.seats.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.seats.status || ssr.seats.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.seats.status || ssr.seats.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.meals != null && ssr.meals != '' && ssr.meals.name && ssr.meals.name != 'NA' ">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Meal: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :
                                              <span title="{{ssr?.meals?.code?.code}} {{ssr?.meals?.code?.name}}">
                                                  {{start_and_end(ssr?.meals?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.meals.status && ssr.meals.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.meals.status || ssr.meals.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.meals.status || ssr.meals.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.baggages != null && ssr.baggages != '' && ssr.baggages.name && ssr.baggages.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Baggage: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) : 
                                              
                                              <span title="{{ssr?.baggages?.code?.code}} {{ssr?.baggages?.code?.name}} ">
                                                  {{start_and_end(ssr?.baggages?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.baggages.status && ssr.baggages.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.baggages.status || ssr.baggages.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.baggages.status || ssr.baggages.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.additionalBaggages != null && ssr.additionalBaggages != '' && ssr.additionalBaggages.name && ssr.additionalBaggages.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                              Addnl. Baggage: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) : 
                                              <span title="{{ssr?.additionalBaggages?.code?.code}} {{ssr?.additionalBaggages?.code?.name}}">
                                                  {{start_and_end(ssr?.additionalBaggages?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.additionalBaggages.status && ssr.additionalBaggages.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.additionalBaggages.status || ssr.additionalBaggages.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.additionalBaggages.status || ssr.additionalBaggages.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.ancillaries != null && ssr.ancillaries != '' && ssr.ancillaries.name && ssr.ancillaries.name != 'NA'">
                                         <ng-container *ngIf="ssr?.ancillaries.code && isArray(ssr?.ancillaries.code)">
                                          <div class="d-flex align-items-center" *ngFor="let item of ssr?.ancillaries.code">
                                              <span class="text-grey text-bold mr-2">
                                              Ancillary: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :
                                              <span title="{{item?.code}} {{item?.name}} ">
                                                  {{start_and_end(item)}}
                                              </span>
                                              <ng-container *ngIf="item && item.status && item.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!item?.status || item?.status == 'SUCCESS' || item?.status == 'notselected') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!item?.status || item?.status == 'SUCCESS' || item?.status == 'notselected' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                         </ng-container>
                                      </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                    <!-- <div class="p-add pl-3"><span>Address:</span>203, FF, IP Colony, Faridabad</div> -->
                    <!-- FIX ME -->
                </div>
                </div>

            </div>
        </div>
        <div class="passenger-section card mb-3 " *ngIf="bookingArr[0].json_doc?.contactdetails?.email || bookingArr[0].json_doc?.contactdetails?.phone ">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row  ">
                <p class="text-bold text-dark-green m-0">Passenger Personal Details</p>
            </div>
            <div class="card-body clearfix ml-0 mr-0 p-0 passenger-box px-4 py-2">
                <div *ngIf="bookingArr[0].json_doc?.contactdetails?.email"> <strong>Email :</strong> {{bookingArr[0].json_doc?.contactdetails?.email}} <i title="Copy Email" class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc?.contactdetails?.email,'Email')"></i></div>
                <div *ngIf="bookingArr[0].json_doc?.contactdetails?.phone"> <strong>Mobile No. :</strong> {{bookingArr[0].json_doc?.contactdetails?.phone}} <i title="Copy Mobile No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc?.contactdetails?.phone,'Mobile No.')"></i></div>
            </div>
        </div>
        
        <!-- passenger section end -->

        <div class="gst-detail card mb-3 mb-2" *ngIf="bookingArr[0].json_doc?.gstDetail?.gstNumber != ''">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row  ">
                <p class="text-bold text-dark-green m-0">GST Details</p>
                <div class="text-center">
                    <i class="fa fa-solid fa-plus-circle" style="color: #8a2be2;font-size: 14px;" (click)="openGstModal()"></i>
                </div>
            </div>
            <div class="card-body row m-0" *ngIf="bookingArr[0].json_doc?.gstDetail?.gstNumber"> 
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Number: <span>{{bookingArr[0].json_doc?.gstDetail?.gstNumber}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Name: <span>{{bookingArr[0].json_doc?.gstDetail?.companyName}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Contact No.: <span>{{bookingArr[0].json_doc?.gstDetail?.phone}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Email: <span>{{bookingArr[0].json_doc?.gstDetail?.email}}</span></span>
                </span>
                <div class="clearfix"></div>
            </div>
        </div>

        <div class="gst-detail card mb-3 mb-2">
                <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
                        <p class="text-bold text-dark-green m-0">Select Fare</p>
                        <div class="d-flex">
                            <p class="text-bold text-dark-green m-0">Balance: </p>
                            <i (click)="refreshCreditBalance()" class="fa fa-refresh btn btn-sm text-info ml-2"></i>
                        </div>
                    </div>
                    <div class="card-body row m-0" style="height: 140px;overflow: auto;">
                        <ng-container *ngFor="let item of bookingArr;  let b = index;">

                            <div class="tooltip-wrap " [ngClass]="{'selFlightColor' : selectedFlightDataIndex === b}" (click)="selectFare(b)">
                              
                               <div>
                                    <button class="btn flight-rate">&#x20B9; {{(item.totalPublishedFare - item.totalServiceCharges) | number : '1.0-0'}}</button>
                               </div> 

                               <div>
                                    <p class="rate-class offer-price mb-0" style="color: black;" >
                                        &#x20B9; {{(item.totalNetPayable - item.totalServiceCharges) | number : '1.0-0'}}
                                    </p>
                               </div>
                              
                               <div>
                                    <p class="fare-type mb-0">{{item.json_doc.fare_type}}</p>
                               </div>
                               
                              <div>
                                    <span class="fare-rule">
                                        <a class="mb-0" style="text-decoration: underline;font-weight: bold;color: #3F51B5;" (click)="getFareRules(b)">Fare Rule</a>
                                    </span> 
                              </div>

                              <div>
                                <p class="fare-type mb-0" style="font-size:9px;text-decoration: underline;color: #3F51B5;">{{getAllLegsBaggages(item.json_doc.segments)}}</p>
                              </div>
                             
                            </div>
                            
                        </ng-container>  

                        <div class="tooltip-wrap-shimmer " *ngIf="upsellFaresLoading">
                            <div  class="tooltip-wrap">
                          
                                <div class="tooltip-shimmer" style="width: 73px;height: 20px;">
                                </div> 
    
                                <div class="tooltip-shimmer" >
                                     <p class="rate-class offer-price mb-0" style="width: 73px;height: 20px;" >
                              
                                     </p>
                                </div>
                               
                                <div class="tooltip-shimmer">
                                     <p class="fare-type mb-0" style="width: 73px;height: 20px;"></p>
                                </div>
                                
                               <div class="tooltip-shimmer">
                                     <span class="fare-rule" style="width: 73px;height: 20px;"> 
                                         <a class="mb-0" style="text-decoration: underline;font-weight: bold;color: #3F51B5;"></a>
                                     </span> 
                               </div>
                              
                            </div>
                        </div>
                   
                <div class="clearfix"></div>
            </div>
        </div>

        <!-- <div class="d-flex align-items-center justify-content-center flex-wrap mt-4 mb-5" *ngIf="bookingArr">           
            <ng-container>
                <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition re-issue" href="javascript:void(0)" *ngIf="!invalidCredit" (click)="issueTicket()">Issue Ticket</a>
            </ng-container>
        </div> -->

    </ng-container>

<div id="snackbar">{{copyFor}} copied..</div>


<div class="page-loader" [ngClass]="active ? 'd-block' : 'd-none'">
    <div class="loader">
      <div
      class=" text-center" >
      <div class="img-container">
        <img
          src="../assets/v3images/animated-rocket.gif"
          alt=""
        />
      </div>
      <div
        class="animated-loader-text d-flex align-items-center justify-content-center"
      >
        <p class="text-white text-center" ><span class="text-bold d-block">Please Wait!</span><small>we are processsing data </small></p>
        <!-- <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div> -->
      </div>
    </div>
    </div>
  </div>

<!-- Re-issue modal -->
<button type="button" #openPassportModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#passportModal"></button>

<div class="passportModalWrapper">
    <div id="passportModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-xl custom-modal modal-dialog-centered">
            <div class="modal-content w-100">
                <form >
                    <div class="modal-header ">
                        <h5 class="modal-title">
                            Passport Details
                        </h5>
                        <button type="button" class="close" #closePassportModal data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="passportForm">
                            <ng-container formArrayName="travellers">
                            <ng-container *ngFor="let passenger of DynamicFormControls.controls; let i = index;">
                            <div class="row ml-0 mr-0 mb-3 align-center-middle" [formGroupName]="i">
                  
                                <span class="passport-name">{{passenger.value.first_name}} {{passenger.value.last_name}}</span>
                                <div class="row dom-traveler-details">
                                    <ng-container>
                                        <div class="col-3">
                                            <mat-form-field class="example-full-width w-100">
                                                <input matInput placeholder="Passport No." value=""
                                                    title="Format : J12345678" pattern="^[a-zA-Z0-9]{1,9}"
                                                    formControlName="passport"
                                                    oninput="this.value = this.value.toUpperCase()" required
                                                    autocomplete="NoAutocomplete">
                                                <mat-error class="matError"
                                                    *ngIf="(!passenger['controls'].passport.valid && passenger['controls'].passport.touched) || (paxSubmitted && passenger['controls'].passport.errors != null)">
                                                    Please enter valid passport number </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3">
                                            <mat-form-field class="w-100">
                                                <mat-select placeholder="Nationality"
                                                    formControlName="nationality">
                                                    <mat-option *ngFor="let country of nationalityArr"
                                                        [value]="country.name">{{country.name}}</mat-option>
                                                </mat-select>
                                                <mat-error class="matError"
                                                    *ngIf="(!passenger['controls'].nationality.valid && passenger['controls'].nationality.touched) || (paxSubmitted && passenger['controls'].nationality.errors != null)">
                                                    nationality is required </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3">
                                            <mat-form-field class="w-100">
                                                <mat-select placeholder="Issuing Country"
                                                    formControlName="issuingCountry">
                                                    <mat-option *ngFor="let country of nationalityArr"
                                                        [value]="country.name">{{country.name}}</mat-option>
                                                </mat-select>
                                                <mat-error class="matError"
                                                    *ngIf="(!passenger['controls'].issuingCountry.valid && passenger['controls'].issuingCountry.touched) || (paxSubmitted && passenger['controls'].issuingCountry.errors != null)">
                                                    issuing country is required </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3">
                                            <mat-form-field class="w-100">
                                                <input matInput [matDatepicker]="picker" class="departure"
                                                    [min]="minDate" (focus)="picker.open()"
                                                    placeholder="Expiry Date" formControlName="expiryDate"
                                                    required autocomplete="NoAutocomplete">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error class="matError"
                                                    *ngIf="(!passenger['controls'].expiryDate.valid && passenger['controls'].expiryDate.touched) || (paxSubmitted && passenger['controls'].expiryDate.errors != null)">
                                                    Expiry date is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
    
                            </div>
                        </ng-container>
                    </ng-container>
                        </form>
                    </div>
            
        </form>
        <div class="modal-footer">
            <button type="button" class="btn btn-green btn-lg" data-dismiss="modal" (click)="savePassportDetails()">Save</button>
        </div>
     </div>
      
        </div>
      </div>
</div>

<button type="button" #openGstDetailsModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#GstModal"></button>

<div class="passportModalWrapper">
    <div id="GstModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-xl custom-modal modal-dialog-centered">
            <div class="modal-content w-100">
                <form >
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Gst Details
                        </h5>
                        <button type="button" class="close" #closeGstDetailsModal data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="gstDetailsForm">
                            <div class="">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="example-full-width w-100">
                                            <input type="text" placeholder="Corporate Name" aria-label="Number" matInput
                                                formControlName="companyName" (keyup)="bindGSTInfo($event)"
                                                (blur)="bindTravellerGstName($event)"
                                                [matAutocomplete]="gstAuto" oninput="this.value = this.value.toUpperCase()"
                                                autocomplete="NoAutocomplete">
                                            <mat-autocomplete #gstAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of gstArr"
                                                    (onSelectionChange)="option.name !=undefined && setGstDetails(option)"
                                                    (focusout)="setGstDetails(option)" [value]="option.name">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="example-full-width w-100">
                                            <input matInput placeholder="Corporate GST Number"
                                                title="Format : 27AAPFU0939F1ZV"
                                                oninput="this.value = this.value.toUpperCase()" value=""
                                                formControlName="gstNumber" autocomplete="NoAutocomplete">
                                            <mat-error class="matError" class="error"
                                                *ngIf="!gstDetailsForm.controls.gstNumber.valid && gstDetailsForm.controls.gstNumber.touched">
                                                please enter valid GST number </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="example-full-width w-100">
                                            <input matInput placeholder="Mobile Number" value=""
                                                oninput="this.value = this.value.toUpperCase()"
                                                formControlName="phone" autocomplete="NoAutocomplete">
                                            <mat-error class="matError" class="error"
                                                *ngIf="!gstDetailsForm.controls.phone.valid && (gstDetailsForm.controls.phone.dirty || gstDetailsForm.controls.phone.touched)">
                                                please enter valid mobile
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="example-full-width w-100">
                                            <input matInput placeholder="Corporate Email"
                                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$" type="email"
                                                value="" oninput="this.value = this.value.toUpperCase()"
                                                formControlName="email" autocomplete="NoAutocomplete">
                                            <mat-error class="matError" class="error"
                                                *ngIf="!gstDetailsForm.controls.email.valid && gstDetailsForm.controls.email.touched">
                                                please enter valid email </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div style="clear: both;"></div>
                            </div>
                        </form>
                    </div>
                </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-green btn-lg" data-dismiss="modal" (click)="saveGstDetails()">Save</button>
                </div>
            </div>
    </div>
    </div>  
</div>


<button type="button" #openFareRuleModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#fareRuleModal"></button>

<div id="fareRuleModal" class="modal mt-2" role="dialog">
    <div class="modal-dialog modal-xl" >
        <div class="modal-content" style="margin: 0 auto">
            <div class="modal-header" style="border: none;">
                <button type="button" class="close" #closeFareRuleModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ng-container *ngIf="fareRules;else noFareRules" class="modal-dialog modal-xl  modal-dialog-centered" style="margin: 0 auto">
                <ng-container class="modal-content">
                    <h3 class="mx-auto my-5 scroll text-center"  *ngIf="isString(fareRules['fareRule']);else structuredFareRule">
                        <div class="fare-rule" style="font-size:15px;" [innerHTML]="fareRules['fareRule']"></div>
                    </h3>
                    <ng-template #structuredFareRule>
                        <h4 class="text-center" *ngIf="bookingArr && bookingArr.length > 0 && bookingArr[0].json_doc && bookingArr[0].json_doc.fare_type">
                            {{bookingArr[0].json_doc.fare_type}} Fare Rules:
                        </h4>          
                        <div class="amedius-fare-rule-area ml-3 mr-3 scroll">
                           <div class="fare-rules-area" *ngIf="bookingArr && bookingArr.length>0 && bookingArr[0].json_doc && bookingArr[0].json_doc.fare_type">   
                                <div class="" >
                                   <div id="{{bookingArr[0].json_doc.fare_type}}" class="tab-pane fadein show active">                                
                                       <ng-container *ngIf="!showLegacyFareRule">
                                            <div *ngFor="let obj of fareRules">
                                                <h5 class="sector"><span>{{obj.seg}}</span></h5>
                                                <div class="fare-rule-section" *ngFor="let item of obj.fareRule | keyvalue">
                                                    <h5>#<span>{{item.key}}</span></h5>
                                                    <table>
                                                        <tbody>
                                                            <tr *ngFor="let fareRuleArr of item.value">
                                                                <td *ngFor="let fareRule of fareRuleArr">
                                                                    <span>{{fareRule}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="showLegacyFareRule && legacyFareRule" >
                                           <mat-accordion>
                                            <div class="parent-card-tab"  *ngFor="let item of legacyFareRule | keyvalue;let lfrIndex = index;">
                                                <mat-expansion-panel class="relative m-0" [expanded]="parentIndex === lfrIndex">
                                                    <mat-expansion-panel-header >
                                                        <mat-panel-title class="parent-header-title">
                                                            <ng-container *ngIf="item.key">
                                                                Sector : {{item.key}}
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value.fareDetails">
                                                                <ng-container *ngIf="item.value.fareDetails['fareBasis']">
                                                                   | Fare Basis :{{item.value.fareDetails['fareBasis']}}
                                                                </ng-container>
                                                                <ng-container *ngIf="item.value.fareDetails['bookingClass']">
                                                                   | Booking Class :{{item.value.fareDetails['bookingClass']}}
                                                                </ng-container>
                                                            </ng-container>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div class="mt-3">
                                                        <mat-accordion>
                                                            <div class="card-tab" *ngFor="let legacyRule of item.value.fareRule">
                                                                <mat-expansion-panel class="relative m-0" >
                                                                    <mat-expansion-panel-header >
                                                                        <mat-panel-title class="child-header-title">
                                                                            {{legacyRule.fareRuleCategoryName}} {{legacyRule.fareRuleCategoryId}}
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>
                                                                    <div>
                                                                        <pre [innerHTML]="legacyRule.fareRule">
                                                                        </pre>
                                                                    </div>
                                                                    <div style="clear: both;"></div>
                                                                </mat-expansion-panel>
                                                            </div>
                                                        </mat-accordion>
                                                    </div>
                                                    <div style="clear: both;"></div>
                                                </mat-expansion-panel>
                                            </div>
                                        </mat-accordion>
                                        </ng-container>
                                    </div>
                                </div>
                           </div>
                       </div>
                       <div class="d-flex justify-content-center" *ngIf="fareRules && fareRules.length > 0;else noFareRule">
                            <button class="btn btn-green btn-sm my-1" type="button" (click)="showLegacyFareRule = !showLegacyFareRule" *ngIf="legacyFareRule"> Show {{showLegacyFareRule ? 'Mini' : 'Legacy'}} fare rule</button>
                       </div>
                        <ng-template #noFareRule>
                            No Fare Rule Found
                        </ng-template>
                    </ng-template>
                </ng-container>
            </ng-container>
            <ng-template #noFareRules>
                <h3 class="text-center">
                    No Fare Rule Found
                </h3>
            </ng-template>
        </div>
       
    </div>
</div>