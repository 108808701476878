import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from './services/common.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


import * as moment from 'moment-timezone';
import { VersionCheckService } from './version-check.service';
import { WebsocketService } from './services/websocket.service';
import { CentrifugeService } from "src/app/services/centrifuge.service";
import { CookiesService } from './services/cookie.service';
import { BookingRequestService } from "src/app/services/booking-request.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'app';
	public loading = false;
	public previousUrl: string = undefined;
	public currentUrl: string = undefined;
	customLoadingTemplate;
	env = environment;
	plateform = window.navigator.platform;
	isHeader = true;
	showFooter = true;
	isLogin = false
	appVersionFromApi;
	showStrip = false
	isModalOpen = false
	userInfo: any
	@ViewChild('newRelease') openModal:ElementRef;
	// @ViewChild('closeModal') closeModal:ElementRef;
	currentVersion;
	myAppURL;
	@ViewChild('refreshForm') refreshForm: ElementRef;
	isPopupVisible: boolean = false;
	showWhatsappLogo: boolean = true;
	constructor(private commonService: CommonService, private route:Router, public vs : VersionCheckService,
    private centrifugeService: CentrifugeService,
	private bookingRequestService: BookingRequestService,
	private webSocketService : WebsocketService,private cookiesService:CookiesService) {
		if(window.location.href.includes('template')) {
			this.isHeader = false;
		}
		else{
			this.isHeader = true;
		}

		
		route.events.subscribe( (event) => {
			if(JSON.parse(localStorage.getItem('user_details'))!= undefined) {
				this.isLogin = true
			}else{
				this.isLogin = false
			}
			if(event['url'] != undefined) {
			   if(event['url'].includes('template') ) {
					this.isHeader = false;
					this.showWhatsappLogo = false;
			  }
			  else{
					this.isHeader = true;
			  }
			  if(this.isLogin){
				this.isHeader = false;
			  }
			  if (
				event["url"].includes('pages/flight;searchId') ||
				event["url"].includes('pages/traveller')   ||
				event["url"].includes('pages/twoway-traveller')   ||
				event["url"].includes('pages/special-round-trip-traveller')    ||
				event["url"].includes('pages/multi-city-lcc-traveller')   ||
				event["url"].includes('pages/multi-city-gds-traveller')  

			) {
				this.showFooter = false
			}else{
				this.showFooter = true
			}
			}      
		  })
	}

	async ngOnInit() {		
		
		if(JSON.parse(localStorage.getItem('user_details'))!= undefined) {
			this.isLogin = true
			this.userInfo = JSON.parse(localStorage.getItem('user_details'))
			this.connectToCentri("CF_EVENTS_"+ this.userInfo.xlAccountCode)


		}else{
			this.isLogin = false
		}
		try {
			this.myAppURL = window.location.href;
			this.isModalOpen = false
			if (this.centrifugeService.handler && this.centrifugeService.connected) {
				this.centrifugeService.handler.disconnect();
			}
			this.connectToCentri("version_control")
			
			this.appVersionFromApi = ""
			this.currentVersion =  localStorage.getItem("app-version")
			let latestVersion = await this.getVersion()
			if(latestVersion && latestVersion!="" && latestVersion!= this.currentVersion && (this.isHeader || this.isLogin)){
				this.appVersionFromApi = latestVersion
				this.openVersionControlModal()
			}
		} catch (error) {
			console.error(error)
		}
		this.commonService.accessLoaderStatus.subscribe(value => {
			this.loading = value;
		})
		this.commonService.showFooterValue.subscribe(value=>{
			if(Number(value)==1){
				this.showFooter = true
			}
		})

	}

	

	getVersion(){
		return new Promise((resolve, reject) => {
			this.commonService.getVersionControl('IBE').subscribe((res : any)=>{
				let respData = JSON.parse(res['_body'])
				if(respData && respData["data"] && respData["data"].length==1){
					resolve(respData["data"][0]["version"])
				}else{
					resolve("")
				}
			
			},error=>{
				this.appVersionFromApi = ""
				resolve("")
				console.log(error)
			})
		})
	}

	openVersionControlModal(){
		this.isModalOpen = true
		this.openModal.nativeElement.click()
	}

	forceReload() {
		this.refreshForm.nativeElement.submit();
	}


	setVersion(){
	   localStorage.setItem("app-version",this.appVersionFromApi)
	   this.showStrip = false
	   this.isModalOpen = false
	   this.forceReload()
	}

	connectToCentri(channelName) {
		try {
		  let ICentrifugeConfig = {
			url: environment.centri_ws,
			token: environment.centri_token,
			user: "Daud",
			timestamp: (Date.now() / 1000) | 0,
			debug: true,
			reconnect: true,
		  };
		  this.centrifugeService.connect(ICentrifugeConfig);
		  this.centrifugeService.handler.subscribe(channelName, (ctx) => {
			  let data = ctx.data
			  let currentVersion = localStorage.getItem('app-version')
				if(data['version'] && data['version']!=currentVersion && (this.isHeader || this.isLogin)){
					this.appVersionFromApi = data['version']
					if(!this.isModalOpen){
					this.showStrip = true
					}
			  	}
				if(data['event']=='refresh_balance'){
					this.bookingRequestService.getCreditLimit(true,0,this.userInfo.affiliateId,"");
				}
				if(data['event']=='refresh_account'){
					localStorage.setItem(
						"user_details",
						JSON.stringify(data["userInfo"])
					);
					this.bookingRequestService.getCreditLimit(true,0,this.userInfo.affiliateId,"");
				}
				if(data['event']=='auto_logout'){
					this.logout()
				}
		  });
		} catch (error) {
		  console.log("error occured to subscribe centrifuge",error);
		}
	  }

	  logout() {
		localStorage.clear();	
		this.route.navigate(['login']);
	}

	togglePopup() {
		this.isPopupVisible = !this.isPopupVisible;
	  }


}
