import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { PostService } from 'src/app/services/post.service';
import { BookingService as BookingRequest } from './../traveller/booking.service';
import { environment } from '../../../environments/environment'
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CalculatePriceService } from 'src/app/services/calculate-price.service';
import { Subscription } from 'rxjs';
import { InitialState } from "src/app/store/reducer";
import { NgRedux } from "@angular-redux/store";
import { InitializeSelectedSSR, ResetAddonSelection, SetSsr, ToggleAddonSummary } from "src/app/store/addons/actions";
import { Store } from '@ngrx/store';
import * as moment from "moment-timezone";
import { NotificationService } from 'src/app/services/notification.service';
@Component({
	selector: 'app-twoway-traveller',
	templateUrl: './twoway-traveller.component.html',
	styleUrls: ['./twoway-traveller.component.scss']
})
export class TwowayTravellerComponent implements OnInit {
	environment = environment
	passengerArr = []
  	toggleAddon:boolean = false
	returnPassengerArr = [];
	activeAddonTab = 'seat-map';

	@ViewChild('closeactiveBtn') closeactiveBtn: ElementRef;
	active = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	travellerForm: FormGroup;
	myDepControl = new FormControl();
	panelOpenState = false;
	departure: any;
	arrival: any;
	departureInboud: any;
	arrivalInbound: any;
	date;
	dateInbound;
	total;
	isOffer = false;
	netPayable: number = 0;
	// selectedFare;
	selectedFares;
	baseFare: any;
	netCommission: number = 0;
	totalFare: any;
	adultBaseFare: any;
	adultTotalFare: any;
	legs = [];
	Inboundlegs = [];
	airlineCode: any;
	adultTaxFare: number = 0;
	childBaseFare: any;
	childTotalFare: any;
	totaltax: any;
	arrivalDate: string;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	isDomestic: boolean = true;
	passengerList: FormArray;
	nationalityArr: any = [];
	childTaxFare: number = 0;
	noOfAdult: any;
	noOfChild: any;
	noOfInfant: any;
	tds: number = 0;
	creditDetail: any = [];
	creditLimit: string;
	invalidCredit: boolean = false;
	inBoundCreditLimit;
	inBountInvalidCredit: boolean = false;
	passengerDetails: any;
	isSpiceJet: boolean = false;
	totalPassengerCount: number = 0;
	totalPaxArr: any = [];
	openTravellerTab: boolean = false;
	infBaseFare: any;
	infTotalFare: any;
	infTaxFare: number = 0;
	baggageArr: any = [];
	noOfAdultInbound: any;
	noOfChildInbound: any;
	noOfInfantInbound: any;
	tdsInbound: number = 0;
	netPayableInbound: number = 0;
	selectedFaresInbound;
	baseFareInbound: any;
	netCommissionInbound: number = 0;
	totalFareInbound: any;
	adultBaseFareInbound: any;
	adultTotalFareInbound: any;
	adultTaxFareInbound: number = 0;
	childBaseFareInbound: any;
	childTotalFareInbound: any;
	childTaxFareInbound: number = 0;
	totaltaxInbound: any;
	totalPassengerCountInbound: number = 0;
	totalPaxArrInbound: any = [];
	totalInbound;
	arrivalDateInbound: string;
	departureDateInbound: string;
	departureTimeInbound: string;
	arrivalTimeInbound: string;
	totalBookingPrice: number = 0;
	airlineCodeInbound: any;
	activeStep = 0;
	infBaseFareInbound: any;
	infTotalFareInbound: any;
	infTaxFareInbound: number = 0;
	sectorArr: any = [];
	sectorInboundArr: any = [];
	adminMarkup: number = 0;
	affMarkUp: number = 0;
	adminInboundMarkup: number = 0;
	affInboundMarkUp: number = 0;
	dummyAdultNameArr: any = [];
	selectedBaggage: any = [];
	showBaggage: boolean = false;
	gstArr: any = [];
	myGstControl = new FormControl();
	outboundSeatMap: any = [];
	inboundSeatMap: any = [];
	showseatloadError: boolean;
	flightRequest: any;
	InwardflightRequest: any;
	isLCC: boolean = false;
	inInBoundLCC: boolean = false;
	outBoundTicketIssueStatus: boolean = false;
	inboundTicketIssueStatus: boolean = false;
	request;
	OnWardsBookingResJson;
	supplierName: string;
	inWardSupplierName: string;
	ssrAvailability = [];
	showloadError: boolean;
	seatMapArr = [];
	InBoundSSRAvailability =[];
	InBoundSeatMapArr = [];

	outboundBaggageCharges = 0;
	inboundBaggageCharges = 0;

	outboundAncillaryCharges = 0;
	inboundAncillaryCharges = 0;

	outboundAdditionalBaggageCharges = 0;
	inboundAdditionalBaggageCharges = 0;

	netTotalayable = 0;

	outboundSeatCharges = 0;
	inboundSeatCharges = 0;

	outboundWheelChairCharges = 0;
	inboundWheelChairCharges = 0;

	outboundMealCharges = 0;
	inboundMealCharges = 0;

	outboundSeletedMealArr;
	inboundSelectedMealArr;

	outboundSeletedSeatArr;
	inboundSelectedSeatArr;

	outboundSelectedBaggageArr;
	inboundSelectedBaggageArr;

	outboundSelectedAncillaryArr;
	inboundSelectedAncillaryArr;

	outboundSelectedAdditionalBaggageArr;
	inboundSelectedAdditionalBaggageArr;

	outboundSelectedWheelChairArr;
	inboundSelectedWheelChairArr;
	
	inBoundNetTotalayable = 0;

	firbaseData = [];
	offerSubscription: Subscription;
	outBoundBookingStatus: any;
	allgstArr: any = [];

	selectedGST: any

	outBoundSsrRequest = false;
	inboundSsrRequest = false;

	airlineArr: any;

	minDate: Date = new Date();

	paxSubmitted: boolean = false;
	contactSubmitted: boolean = false;

	outboundFareType = "";
	inboundFareType = "";

	current_time = new Date()
	date_diff;
	showHoldOutBound: boolean = false;
	date_diffInbound;
	showHoldInBound: boolean = false;
	outBoundDirection: any;
	inBoundDirection: any;
	warningMessage: string;
	showAddons: boolean = true
	supplierType: any;
	InboundSupplierType: any;
	inboundAffiliateMarkup: number;
	outboundAffiliateMarkup: number;
	isDiAccount: boolean;
	outboundLimitType: any;
	inboundLimitType: any;
	selectedPaymentMethod: any;

	@ViewChild('showPaymentInfo') showPaymentInfo: ElementRef;
	onlinePaymentAmount: any;
	onlinePaymentCharge: any;
	selectedFlightDirectionForPayment: string;
	errorMessage: string;
	isResponse: boolean;
	paymentStatusResponse: any;
	affiliateDetails: any;
	originalNetPayable: any;



	@ViewChild('showDuplicateBooking') showDuplicateBooking: ElementRef;
	@ViewChild('showPriceChange') showPriceChange: ElementRef;
	duplicateBookingMsg: any;
	disableBtn: boolean;
	disableBtnInbound: boolean;

	isIncreasedPrice = false;
	oldTtotalNetPayable = 0;

	oldOutboundPrice  = 0;
	oldInboundPrice = 0;

	currentBooking = 'outbound';
	isHoldOutBoundBooking: boolean = false;
	isHoldInBoundBooking: boolean = false;
	messageList;

	@ViewChild('showHoldInfo') showHoldInfo: ElementRef;
	inboundSelected: boolean = false;
	allowHold: any;
	inboundServiceCharge: number;
	inboundGST: number;
	outboundServiceCharge: number;
	outboundGST: number;
	showSelectedSeatError: boolean = false;
	selectedSsrInRedux: any;
	outBoundSubFareType: string;
	inBoundSubFareType: string;
	insurancePackageData:any;
	isInsuranceCoverExpanded = false;
	isInsuranceSelected = false;
	insuranceCoverViewCount = 2
	insuranceAmountPerPerson= 0;
	totalInsuranceAmount= 0;
	insuranceTravellerList = [];
	isTravellerInsuranceFormValid = true;
	selectedInfantAssociate = new Map();
	warningMessageType: string;
	inBoundHasFreeMeal: boolean;
	inBoundHasFreeBaggage: boolean;
	outBoundHasFreeMeal: boolean;
	outBoundHasFreeBaggage: boolean;
	totalFreeMealPerLegOutBound = 0;
	totalFreeMealPerLegInBound = 0;
	maxDobDate = {
		"Adult" : new Date(moment().subtract(12, 'years')),
		"Child" : new Date(moment().subtract(2, 'years')),
		"Infant" : new Date()
	  }
	  // moment().subtract(12, 'years')
	minDobDate = {
		"Adult" : new Date(moment().subtract(100, 'years')),
		"Child" : new Date(moment().subtract(12, 'years')),
		"Infant" : new Date(moment().subtract(2, 'years'))
	}
	showseatloadErrorMessage: any;
	sectionIndex={
		FLIGHT_DETAIL : 0,
		TRAVELLER_INFO : 1,
		PERSONAL_INFO : 2,
		GST_DETAILS : 3,
		ADD_ONS : 4,
		TRAVEL_INSURANCE : 5,
		PAYMENT : 6
	}
	isStudentFare = false
	isSeniorCitizenFare = false
	isGfsSupplier = false
	
	inBoundSsrWarningMessage = false;
	outBoundSsrWarningMessage = false;
	
	constructor(private router: Router,
		private commonService: CommonService,
		private fb: FormBuilder,
		private affiliateService: AffiliateService,
		private bookingService: BookingService,
		private bookingServiceRequest: BookingRequest,
		private postService: PostService,
		private returnBookingService: BookingRequest,
		private bookingRequestService: BookingRequestService,
		private travellerService: TravellerService,
		private excelServc: ExcelService,
		private calculatePriceService: CalculatePriceService,		
		private activatedRoute: ActivatedRoute,
        private ngRedux: NgRedux<InitialState>,
		private store: Store<InitialState>,
		private notificationService : NotificationService) {
		this.showAddons = true
		this.ngRedux.dispatch(ResetAddonSelection({}))
        this.ngRedux
			.select('SSrReducers')
			.subscribe((items : any) => {
				this.selectedSsrInRedux = items.selectedSsr;
			})
		this.ngRedux
			.select('CommonReducers')
			.subscribe((items : any) => {
				if(items.globalMessage){
					this.messageList = items.globalMessage
				}
		});
		this.store.select('flightReducers').subscribe((items : any) => {
			this.isOffer = items.showOfferPrice ? items.showOfferPrice : false
			});

			this.ngRedux
		.select('InsuranceTravellerReducers')
		.subscribe((items : any) => {
      if(items.insuranceTravellers)this.insuranceTravellerList = items.insuranceTravellers.value;
		});

	}
	data: any = [];
	InboundData: any = [];
	passengers = [1, 2, 3]
	ngOnInit() {
		this.messageList = this.notificationService.messageObj

		// let bookingRes = {
		// 	bookingId: "AT5004152",
		// 	pnr: null,
		// 	message: "Selected Seat is not available now",
		// 	supplierCode: null,
		// 	bookingResponse: null,
		// 	supplierInfo: null,
		// 	isRevertibleException: true,
		// 	priceChanged: false,
		// 	messageDetails: "Failed to assign seat(s) for passenger KAMESH TESTT on the flight G8 717  DELAMD Detail: Seats cannot be assigned on flight segment G8 717  DEL-AMD for 3/26/2020 due to either one of the following: a seat is marked as restricted, anchor unit Y/7A was not selected, insufficient number of available seats for 1 passenger (1 total). "
		// }
		// this.showError('warning-box',bookingRes['message'],bookingRes['messageDetails'])
		// this.showError('warning-box',bookingRes['message'],bookingRes['messageDetails'])
		window.scroll(0, 0);
		this.selectedPaymentMethod = {
			'outbound': "agent-balance",
			'inbound': "agent-balance"
		}
		this.allowHold = {
			'outbound': false,
			'inbound':	false
		}
		this.onlinePaymentAmount = {
			'outbound': 0,
			'inbound': 0
		}
		this.onlinePaymentCharge = {
			'outbound': 0,
			'inbound': 0
		}
		this.originalNetPayable = {
			'outbound': 0,
			'inbound': 0
		}
		if (document.getElementsByClassName('modal-backdrop')[0] != undefined) {
			document.getElementsByClassName('modal-backdrop')[0].classList.remove('modal-backdrop')
		}
		this.outBoundBookingStatus = "";
		this.travellerForm = this.fb.group({
			"corporateGstNumber": ['', Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$")],
			"corporateName": [''],
			"corporateMobile": ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"corporateEmail": [''],
			'additionalComments': [''],
			"passengerList": this.fb.array([]),
			"email": [Validators.required],
			"phone": ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
			"mobile": [Validators.required, [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"paxDetail": this.fb.array([]),
			"isInBoundMaskFareEnabled":[false],
			"isOutBoundMaskFareEnabled":[false]
		})

	
		this.bookingRequestService.getLimit.subscribe(res => {
			if (res['direction'] == 'outbound') {
				this.creditLimit = res['creditLimit'];
				this.invalidCredit = res['invalidCredit'];
				this.outboundLimitType = res['type'];
			}
			if (res['direction'] == 'inbound') {
				this.inBoundCreditLimit = res['creditLimit'];
				this.inBountInvalidCredit = res['invalidCredit'];
				this.inboundLimitType = res['type'];
			}
		})

		//
		if (localStorage.user_details != undefined) {
			if (JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else {
				this.isDiAccount = false;
			}
		}
		this.outBoundSubFareType = ''
		this.inBoundSubFareType = ''

		// this.getCreditLimit();	// get credit limit of affiliate
		this.getNationality();	// bind countries into passport issuing country and nationality
		this.makeOutboundData(); // bind outbound data
		this.makeInboundData();	// bind inbound flight data
		// this.generateBookingRef();
	
		this.commonService.getAirline(`airline`).subscribe(res => {
			this.airlineArr = JSON.parse(res['_body']);
			// console.log('Airline MAster', this.airlineArr)
		});
		if (localStorage.user_details != undefined) {
			this.affiliateDetails = JSON.parse(localStorage.user_details)
		} else {
			this.affiliateDetails = null
		}

		var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		this.checkPayment(url)

	}

	// @HostListener('window:beforeunload', ['$event'])
	// unloadNotification($event: any) {
	//     if (this.hasUnsavedData()) {
	//         $event.returnValue =true;
	//     }
	// }

	checkPayment(url) {
		if (url.includes("paymentIndex=1")) {
			this.active = true
			this.activatedRoute.queryParams.subscribe(params => {
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];

				var formValue = JSON.parse(localStorage.getItem('formValue'));
				setTimeout(() => {
					this.setFormValue(formValue)
				}, 3000);
				if (!(location.href.includes('error=true')) && formValue != null) {
					let obj = {
						"orderId": orderID,
						"trackingId": transId,
						"amount": amount,
						"key": hasKey,
						"status": "success"
					}
					formValue['chequeNumber'] = orderID;
					formValue['transactionRef'] = transId;
					let selectedFlightDirectionForPayment = localStorage.getItem('selectedFlightDirectionForPayment')
					try {
						if (selectedFlightDirectionForPayment == 'outbound') {
							if (formValue.baggage) {
								this.getOutBoundBaggageEvent(formValue.baggage)
							}
							if (formValue.additionalBaggage) {
								this.getOutBoundAdditionalBaggageEvent(formValue.additionalBaggage)
							}
							if (formValue.ancillary) {
								this.getOutBoundAncillaryEvent(formValue.ancillary)
							}
							if (formValue.seats) {
								this.getOutboundSeatEvent(formValue.seats)
							}
							if (formValue.meals) {
								this.getOutboundMealEvent(formValue.meals)
							}
							if (formValue.inBoundBaggages) {
								this.getInboundBaggageEvent(formValue.inBoundBaggages)
							}
							if (formValue.inBoundAdditionalBaggage) {
								this.getInboundAdditionalBaggageEvent(formValue.inBoundAdditionalBaggage)
							}
							if (formValue.inBoundAncillary) {
								this.getInboundAncillaryEvent(formValue.inBoundAncillary)
							}
							if (formValue.inBoundSeats) {
								this.getInboundSeatEvent(formValue.inBoundSeats)
							}
							if (formValue.inBoundMeals) {
								this.getinboundMealEvent(formValue.inBoundMeals)
							}

							if (formValue.wheelChairs) {
								this.getOutBoundWheelChairEvent(formValue.wheelChairs);
							}

							if (formValue.inboundWheelChairs) {
								this.getInBoundWheelChairEvent(formValue.inboundWheelChairs);
							}

							
						} else {
							if (formValue.baggage) {
								this.getInboundBaggageEvent(formValue.baggage)
							}
							if (formValue.seats) {
								this.getInboundSeatEvent(formValue.seats)
							}
							if (formValue.meals) {
								this.getinboundMealEvent(formValue.meals)
							}

							if (formValue.wheelChairs) {
								this.getInBoundWheelChairEvent(formValue.wheelChairs);
							}
						}
					} catch (error) {
						console.log('Error while setting Add-ons : ', error)
					}


					if (!params.hasOwnProperty('ft-cash')) {
						this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data => {
							if (data['_body'] != "") {
								let response = JSON.parse(data['_body'])
								if (response.length > 0) {
									this.isResponse = true;
									this.paymentStatusResponse = response[0].json_doc_response;
									// window.history.replaceState({}, document.title, "/#/pages/" + "twoway-traveller");
									if (selectedFlightDirectionForPayment == 'outbound') {
										// console.log('Do nothing')
										// this.active = false
										this.doOutBoundBooking(formValue, false)
									} else {
										this.doInBoundBooking(formValue, false)
									}
								}
								else {
									this.isResponse = false;
									console.log('Failed Payment')
								}


							}
							else {
								this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
								this.errorMessage = "Sorry ! Unable to process now, please try again.";
								console.log(this.errorMessage)
							}
						}, error => {
							console.log(error)
						})
					} else {
						console.log('Booking with ft-Cash')
						// window.history.replaceState({}, document.title, "/#/pages/" + "twoway-traveller");
						if (selectedFlightDirectionForPayment == 'outbound') {
							this.doOutBoundBooking(formValue, false)
						} else {
							this.doInBoundBooking(formValue, false)
						}
					}

				}
				else {
					this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
					this.errorMessage = "Sorry ! Unable to process now, please try again.";
					console.log(this.errorMessage)
				}
				console.log(params); // Print the parameter to the console. 
			});
		}
	}

	setFormValue(formValue) {
		this.travellerForm.controls.corporateName.setValue(formValue.corporateName);
		this.travellerForm.controls.corporateGstNumber.setValue(formValue.corporateGstNumber);
		this.travellerForm.controls.corporateMobile.setValue(formValue.corporateMobile);
		this.travellerForm.controls.corporateEmail.setValue(formValue.corporateEmail);
		this.travellerForm.controls.email.setValue(formValue.email);
		this.travellerForm.controls.phone.setValue(formValue.phone);
		this.travellerForm.controls.mobile.setValue(formValue.mobile);
		for (let i = 0; i < formValue.passengerList.length; i++) {
			const values = formValue.passengerList[i]
			this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstName);
			this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastName);
			this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
			if (!this.isDomestic) {
				this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passportNo);
				this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
				this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuingCountry);
				this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiryDate);
				this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
			}
			this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seatPrefences);
			this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.specialServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.mealServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggageServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.travellerId);
		}
	}

	getOutBoundBaggage(selectedFlight) {
		console.log('Selected Outbound flight before appending baggage', selectedFlight)
		let flightRequest = JSON.parse(sessionStorage.ActiveSearchRequest)

		let supplierInfo = selectedFlight[0].fares[0].supplierInfo
		if (supplierInfo == null) {
			return false
		}
		let supplierName = supplierInfo.SUPPLIER
		if (supplierName == undefined) {
			supplierName = selectedFlight[0].airline.code
		}
		let reqObj = {
			"supplierName": supplierName,
			"classOfService": supplierInfo.ClassOfService,
			"fareBasisCode": supplierInfo.FareBasisCode,
			"ruleNumber": supplierInfo.RuleNumber,
			"signature": selectedFlight[0].supplierInfo.Signature,
			"supplierInfo": {
				"FareSellKey": supplierInfo.FareSellKey,
				"JourneySellKey": supplierInfo.JourneySellKey,
				"travellers": {
					"ADT": flightRequest.noOfAdults,
					"CHD": flightRequest.noOfChild,
					"INF": flightRequest.noOfInfants
				},
				"FareMap": supplierInfo,
				"FlightNumber": selectedFlight[0].segments[0].legs[0].flightNumber,
				"BoardPoint": selectedFlight[0].segments[0].legs[0].originAirport.code,
				"OffPoint": selectedFlight[0].segments[0].legs[0].destinationAirport.code,
				"DepartureDateTime": selectedFlight[0].segments[0].legs[0].departureDateTime,
				"ArrivalDateTime": selectedFlight[0].segments[0].legs[0].arrivalDateTime
			}
		}
		console.log('Baggage req :', reqObj)
		this.travellerService.getBaggage(reqObj).subscribe(res => {
			let freeCheckInBaggage = JSON.parse(res['_body'])
			let segment = selectedFlight[0].segments
			for (let i = 0; i < segment.length; i++) {
				const legs = segment[i].legs;
				for (let j = 0; j < legs.length; j++) {
					const elm = legs[j];
					if (!elm.hasOwnProperty('freeCheckInBaggage')) {
						elm['freeCheckInBaggage'] = freeCheckInBaggage
					}
					else {
						if (elm.freeCheckInBaggage == null || elm.freeCheckInBaggage == undefined) {
							elm['freeCheckInBaggage'] = freeCheckInBaggage
						}
					}

				}

			}

			selectedFlight[0].segments = segment
			console.log('Selected Outbound flight after appending baggage', selectedFlight)
		},
			err => {
				console.log('Error', err)
			})
	}
	getInboundBaggage(selectedFlight) {
		console.log('Selected Inbound flight before appending baggage', selectedFlight)
		let flightRequest = JSON.parse(sessionStorage.ActiveSearchRequest)

		let supplierInfo = selectedFlight[0].fares[0].supplierInfo
		if (supplierInfo == null) {
			return false
		}
		let supplierName = supplierInfo.SUPPLIER
		if (supplierName == undefined) {
			supplierName = selectedFlight[0].airline.code
		}
		let reqObj = {
			"supplierName": supplierName,
			"classOfService": supplierInfo.ClassOfService,
			"fareBasisCode": supplierInfo.FareBasisCode,
			"ruleNumber": supplierInfo.RuleNumber,
			"signature": selectedFlight[0].supplierInfo.Signature,
			"supplierInfo": {
				"FareSellKey": supplierInfo.FareSellKey,
				"JourneySellKey": supplierInfo.JourneySellKey,
				"travellers": {
					"ADT": flightRequest.noOfAdults,
					"CHD": flightRequest.noOfChild,
					"INF": flightRequest.noOfInfants
				},
				"FareMap": supplierInfo,
				"FlightNumber": selectedFlight[0].segments[0].legs[0].flightNumber,
				"BoardPoint": selectedFlight[0].segments[0].legs[0].originAirport.code,
				"OffPoint": selectedFlight[0].segments[0].legs[0].destinationAirport.code,
				"DepartureDateTime": selectedFlight[0].segments[0].legs[0].departureDateTime,
				"ArrivalDateTime": selectedFlight[0].segments[0].legs[0].arrivalDateTime
			}
		}
		console.log('Inbound Baggage req :', reqObj)
		this.travellerService.getBaggage(reqObj).subscribe(res => {
			let freeCheckInBaggage = JSON.parse(res['_body'])
			let segment = selectedFlight[0].segments
			for (let i = 0; i < segment.length; i++) {
				const legs = segment[i].legs;
				for (let j = 0; j < legs.length; j++) {
					const elm = legs[j];
					if (!elm.hasOwnProperty('freeCheckInBaggage')) {
						elm['freeCheckInBaggage'] = freeCheckInBaggage
					}
					else {
						if (elm.freeCheckInBaggage == null || elm.freeCheckInBaggage == undefined) {
							elm['freeCheckInBaggage'] = freeCheckInBaggage
						}
					}

				}

			}

			selectedFlight[0].segments = segment
			console.log('Selected Inbound flight after appending baggage', selectedFlight)
		},
			err => {
				console.log('Error', err)
			})
	}

	selectGST(item) {
		this.selectedGST = {}
		this.selectedGST = item
	}

	public getOutBoundBaggageEvent(data) {
		this.outboundBaggageCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.outboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.outboundSelectedBaggageArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}

	public getOutBoundWheelChairEvent(data) {
		this.outboundWheelChairCharges = 0;
		data = data.flat()
		data.forEach((v) => {
		  if (v.wheelChairs != null && v.wheelChairs != undefined) {
			v.wheelChairs.forEach((el) => {
			  if (el.price != undefined) {
				this.outboundWheelChairCharges += Number(el.price);
			  }
			});
		  }
		});
		this.outboundSelectedWheelChairArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false,false);
	  }

	  public getInBoundWheelChairEvent(data) {
		this.inboundWheelChairCharges = 0;
		data = data.flat()
		data.forEach((v) => {
		  if (v.wheelChairs != null && v.wheelChairs != undefined) {
			v.wheelChairs.forEach((el) => {
			  if (el.price != undefined) {
				this.inboundWheelChairCharges += Number(el.price);
			  }
			});
		  }
		});
		this.inboundSelectedWheelChairArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false,false);
	  }

	public getInboundBaggageEvent(data) {
		this.inboundBaggageCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.inboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.inboundSelectedBaggageArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(true, false);
	}

	public getOutboundMealEvent(data) {
		this.outboundMealCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.outboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.outboundSeletedMealArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}

	public getinboundMealEvent(data) {
		this.inboundMealCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.inboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.inboundSelectedMealArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}

	public getOutboundSeatEvent(data) {
		this.outboundSeatCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.seats != null && v.seats != undefined) {
				v.seats.forEach(el => {
					if (el.price != undefined) {
						this.outboundSeatCharges += Number(el.price)
					}
				})
			}
		})
		this.outboundSeletedSeatArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}

	public getInboundSeatEvent(data) {
		this.inboundSeatCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.seats != null && v.seats != undefined) {
				v.seats.forEach(el => {
					if (el.price != undefined) {
						this.inboundSeatCharges += Number(el.price)
					}
				})
			}
		})
		this.inboundSelectedSeatArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(true, false);
	}

	public getOutBoundAdditionalBaggageEvent(data) {
		this.outboundAdditionalBaggageCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.additionalBaggage != null && v.additionalBaggage != undefined) {
				v.additionalBaggage.forEach(el => {
					if (el.price != undefined) {
						this.outboundAdditionalBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.outboundSelectedAdditionalBaggageArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}

	public getInboundAdditionalBaggageEvent(data) {
		this.inboundAdditionalBaggageCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.additionalBaggage != null && v.additionalBaggage != undefined) {
				v.additionalBaggage.forEach(el => {
					if (el.price != undefined) {
						this.inboundAdditionalBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.inboundSelectedAdditionalBaggageArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(true, false);
	}


	public getOutBoundAncillaryEvent(data) {
		this.outboundAncillaryCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.ancillary != null && v.ancillary != undefined) {
				v.ancillary.forEach((el) => {
				  if(el.selectedItems){
					el.selectedItems.forEach(element => {
					  this.outboundAncillaryCharges += Number(element.totalFare);
					});
				  }
				});
			  }


		})
		this.outboundSelectedAncillaryArr = data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(false, false);
	}
	public getInboundAncillaryEvent(data) {
		this.inboundAncillaryCharges = 0;
		data = data.flat();
		data.forEach(v => {
			if (v.ancillary != null && v.ancillary != undefined) {
				v.ancillary.forEach((el) => {
				  if(el.selectedItems){
					el.selectedItems.forEach(element => {
					  this.inboundAncillaryCharges += Number(element.totalFare);
					});
				  }
				});
			  }


		})
		this.inboundSelectedAncillaryArr= data;
		this.ngRedux.dispatch(SetSsr(data));
		this.calculateNetPayable(true, false);
	}

	calculateNetPayable(inbound, loadRepricing) {
		if (inbound) {
			this.oldTtotalNetPayable = this.inBoundNetTotalayable;
			this.netPayableInbound = this.totalFareInbound;
			if (this.selectedFaresInbound[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
				this.inboundAffiliateMarkup = Math.round(this.selectedFaresInbound[0].fareComponents.AFFILIATE_MARKUP);
				this.inboundServiceCharge = Math.round(this.selectedFaresInbound[0].fareComponents.ADMIN_SERVICE_CHARGE);
				this.inboundGST = Math.round(this.selectedFaresInbound[0].fareComponents.GST);
				this.netPayableInbound = Math.round(this.selectedFaresInbound[0].fareComponents.NET_PAYABLE);
				this.netPayableInbound = Math.round(this.netPayableInbound + this.inboundMealCharges + this.inboundSeatCharges + this.inboundBaggageCharges + this.inboundAncillaryCharges + this.inboundAdditionalBaggageCharges);
			}
			this.originalNetPayable['inbound'] = this.netPayableInbound
			if (this.selectedPaymentMethod['inbound'] != 'agent-balance') {
				this.onlinePaymentAmount['inbound'] = Math.round(Number(this.netPayableInbound) + (Number(this.netPayableInbound) * Number(environment.payment_gateway_charges)) / 100);
				this.onlinePaymentCharge['inbound'] = Math.round((Number(this.netPayableInbound) * Number(environment.payment_gateway_charges)) / 100);
			}
			this.inBoundNetTotalayable = Math.round(this.totalFareInbound + this.inboundMealCharges + this.inboundSeatCharges + this.inboundBaggageCharges + + this.inboundAncillaryCharges + this.inboundAdditionalBaggageCharges);
			this.InboundData[0].fares[0].total = this.inBoundNetTotalayable
			this.bookingRequestService.getCreditLimit(this.inInBoundLCC, this.inBoundNetTotalayable, this.flightRequest.affiliate, 'inbound');
			if(!loadRepricing) {
				this.oldInboundPrice = this.inBoundNetTotalayable
			}
			else{
				this.oldOutboundPrice = this.netTotalayable;
			}
		} else {
			this.oldTtotalNetPayable = this.netTotalayable;			
			this.netPayable = this.totalFare;
			if (this.selectedFares[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
				this.outboundAffiliateMarkup = Math.round(this.selectedFares[0].fareComponents.AFFILIATE_MARKUP);
				this.outboundServiceCharge = Math.round(this.selectedFares[0].fareComponents.ADMIN_SERVICE_CHARGE);
				this.outboundGST = Math.round(this.selectedFares[0].fareComponents.GST);
				this.netPayable = Math.round(this.selectedFares[0].fareComponents.NET_PAYABLE);
				this.netPayable = Math.round(this.netPayable + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges + this.outboundAncillaryCharges + this.outboundAdditionalBaggageCharges);
			}

			this.originalNetPayable['outbound'] = this.netPayable
			if (this.selectedPaymentMethod['outbound'] != 'agent-balance') {
				this.onlinePaymentAmount['outbound'] = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
				this.onlinePaymentCharge['outbound'] = Math.round((Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
			}
			this.netTotalayable = Math.round(this.totalFare + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges + this.outboundAncillaryCharges + this.outboundAdditionalBaggageCharges);
			this.data[0].fares[0].total = this.netTotalayable
			this.bookingRequestService.getCreditLimit(this.isLCC, this.netTotalayable, this.flightRequest.affiliate, 'outbound');
			if(!loadRepricing) {
				this.oldOutboundPrice = this.netTotalayable;
			}
		}
		this.totalBookingPrice = this.inBoundNetTotalayable + this.netTotalayable;

	}

	refreshCreditLimit(inbound) {
		if (inbound) {
			this.bookingRequestService.getCreditLimit(this.inInBoundLCC, this.inBoundNetTotalayable, this.flightRequest.affiliate, 'inbound');
		}
		else {
			this.bookingRequestService.getCreditLimit(this.isLCC, this.netTotalayable, this.flightRequest.affiliate, 'outbound');
		}
	}

	ngOnDestroy() {
		try {
			// this.subscription.unsubscribe();
		} catch (error) {
			console.log('Error occured while unsubscribing on ngDestroy : ', error)
		}
	}

	// get controls value of passengerlist formarray
	get DynamicFormControls() {
		return <FormArray>this.travellerForm.get('passengerList');
	}

	// function to create passengerlist formarray object for traveller form
	buildItem(passengerType) {
		return this.fb.group({
			"title": [''],
			"firstName": ['', Validators.required],
			'lastName': ['', Validators.required],
			'gender': ['', Validators.required],
			'dob': [''],
			'passportNo': [''],
			'nationality': [''],
			'issuingCountry': [''],
			'associateInfo': [''],
			'expiryDate': [''],
			'passengerType': [passengerType],
			'frequentflyers': [''],
			'frequentflyersNumber': [''],
			'specialServices': [''],
			'mealServices': [''],
			'seatPrefences': [''],
			'baggageServices': [''],
			'travellerId': [''],
			'studentId': [""],
			'seniorCitizenId' : [""],
			'student' : false,
			'seniorCitizen' : false,
		})
	}
	setValidator(i) {
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators([Validators.required]);
			this.travellerForm.controls.passengerList["controls"][i].controls.passportNo.setValidators([Validators.required]);
			this.travellerForm.controls.passengerList["controls"][i].controls.expiryDate.setValidators([Validators.required]);
		
		} else {
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators(null);
			this.travellerForm.controls.passengerList["controls"][i].controls.passportNo.setValidators(null);
			this.travellerForm.controls.passengerList["controls"][i].controls.expiryDate.setValidators(null);
		}
		if (this.data[0] != undefined && this.InboundData[0] != undefined) {
			if (this.data[0].supplierCode == 'airasia' || this.InboundData[0].supplierCode == 'airasia_v2') {
				this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValidators([Validators.required]);
			}
		}
		if(this.selectedFares && this.selectedFares[0] && this.selectedFares[0].supplierInfo && this.selectedFares[0].supplierInfo.subPriceType){
			if(this.selectedFares[0].supplierInfo && this.selectedFares[0].supplierInfo.subPriceType == 'STUD'){
				this.travellerForm.controls.passengerList["controls"][i].controls.studentId.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList["controls"][i].controls.student.setValue(true);
				this.isStudentFare = true
			}
			if(this.selectedFares[0].supplierInfo && this.selectedFares[0].supplierInfo.subPriceType == 'SRCT'){
				this.travellerForm.controls.passengerList["controls"][i].controls.seniorCitizenId.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList["controls"][i].controls.seniorCitizen.setValue(true);
				this.travellerForm.controls.passengerList["controls"][i].controls.dob.setValidators([Validators.required]);
				this.isSeniorCitizenFare = true
				this.maxDobDate["Adult"] =  new Date(moment().subtract(60, 'years'))
				this.minDobDate["Adult"] =  new Date(moment().subtract(200, 'years'))
			}
		}
		if(this.selectedFaresInbound && this.selectedFaresInbound[0] && this.selectedFaresInbound[0].supplierInfo && this.selectedFaresInbound[0].supplierInfo.subPriceType){
			if(this.selectedFaresInbound[0].supplierInfo && this.selectedFaresInbound[0].supplierInfo.subPriceType == 'STUD'){
				this.travellerForm.controls.passengerList["controls"][i].controls.studentId.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList["controls"][i].controls.student.setValue(true);
				this.isStudentFare = true
			}
			if(this.selectedFaresInbound[0].supplierInfo && this.selectedFaresInbound[0].supplierInfo.subPriceType == 'SRCT'){
				this.travellerForm.controls.passengerList["controls"][i].controls.seniorCitizenId.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList["controls"][i].controls.seniorCitizen.setValue(true);
				this.travellerForm.controls.passengerList["controls"][i].controls.dob.setValidators([Validators.required]);
				this.isSeniorCitizenFare = true
				this.maxDobDate["Adult"] =  new Date(moment().subtract(60, 'years'))
				this.minDobDate["Adult"] =  new Date(moment().subtract(200, 'years'))
			}
		}
		this.travellerForm.updateValueAndValidity();
	}
	// function to bind pax details 
	bindDestination(val) {
		val = val.currentTarget.value;
		let valueLength  = 3
		if(this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')){
		    valueLength = 2;
		}
		if (val.length >= valueLength) {
			this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
				let data: any
				data = res;
				// let uniqueArr = [];
				// data.forEach(el => {
				// 	let filteredArr = uniqueArr.filter((v) => {
				// 		if (v.firstname == el.firstname) {
				// 			return v;
				// 		}
				// 	})
				// 	if (filteredArr.length == 0) {
				// 		uniqueArr.push(el)
				// 	}
				// });
				this.passengerDetails = data;
			})
		}
	}
	checkifExist(values) {
		let tArr;
		this.travellerService.checkifExist(values).subscribe(res => {
			tArr = res
			if (tArr.length == 0) {
				this.travellerService.saveAllTraveller(values).subscribe(res => {
					console.log('saved', res)
				})
			}
		})
	}
	setPersonalDetails() {
		let user_details = JSON.parse(localStorage.user_details)
		if (!this.travellerForm.controls.email.touched) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		if (this.travellerForm.controls.email.value == null) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		this.travellerService.viewAllGSTbyid().subscribe(res => {
			this.allgstArr = res
			if (this.allgstArr.length > 0) {
				console.log('All Saved GST', this.allgstArr)
			}
		},
			err => {

			})
	}
	// fucntion to set pax details for passengers after first name selection
	setPaxDetails(values, i) {
		this.paxSubmitted = false
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstname);
		this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastname);
		this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passport_no);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
			this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuing_country);
			this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiry_date);
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
		}
		this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seat_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.special_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.meal_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggage_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.id);
		this.bindDummyAdultArr("")
	}
	// function to get countries list
	getNationality() {
		this.affiliateService.getCountries().subscribe(res => {
			this.nationalityArr = JSON.parse(res["_body"]);

			let activeSearchRequest = JSON.parse(sessionStorage.getItem("ActiveSearchRequest"))
			let isDomestic = activeSearchRequest.domestic;
			
			if(!isDomestic) {
				for(let i = 0; i < this.DynamicFormControls.controls.length; i++) {
					this.DynamicFormControls.controls[i]['controls']['issuingCountry'].setValue('India')
					this.DynamicFormControls.controls[i]['controls']['nationality'].setValue('India')
				}
			}
		})
	}
	// function to bind traveller first name
	bindTravellerFirstName(e, i, type, passenger) {
		// if (type == 'Infant') {
		// 	this.bindDummyAdultArr(e.target.value)
		// }
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(e.target.value);
		if (e.currentTarget.value.length > 2 && e.key == 'Tab') {
			if (passenger.length > 0 && passenger != undefined) {
				this.setPaxDetails(passenger[0], i);
			}
		}
		if (type == 'Adult') {
			this.bindDummyAdultArr(e.target.value)
		}
	}
	setGender(value, i) {
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		if (value == 'Mr') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		}
		if (value == 'Mrs') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
		if (value == 'Ms') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
	}
	// function to issue ticket for booking request
	async issueOutBoundTicket(values, isHold, forceBooking, checkDuplicate) {

		values.hideFaresOnAirlineTicket = this.travellerForm.value.isOutBoundMaskFareEnabled
		this.currentBooking = 'outbound';
		this.active = true;
		this.messageList['messageList'] = []
		this.notificationService.showMessage(this.messageList)
		const invalid = [];
		this.disableBtn = true
		const controls = this.travellerForm.controls;
		for (const name in controls) {
			if (controls[name].invalid ) {
				invalid.push(name);
				if(name == 'passengerList'){
					this.paxSubmitted = true
					this.setStep(this.sectionIndex.TRAVELLER_INFO);
					break;
				  }else{
					this.contactSubmitted = true
					this.setStep(this.sectionIndex.PERSONAL_INFO)
					break;
				  }
		  
			}
		}
		console.log('invalid field on submit', invalid)
		if (invalid.length > 0) {
			this.panelOpenState = true;
			this.openTravellerTab = true;
			this.setStep(this.sectionIndex.TRAVELLER_INFO)
			this.disableBtn = false
			this.active = false;
			return false;
		}
		if (this.invalidCredit && !isHold) {
			this.active = false;
			this.disableBtn = false
			return false
		}
		if ((this.outboundFareType == "SME" || this.inboundFareType == 'SME') 
			&& (this.airlineCode != '6E' && this.airlineCodeInbound != '6E') 
			&& (this.airlineCode != '6E_SME' && this.airlineCodeInbound != '6E_SME')
			&& (this.airlineCode != 'AMADEUS_CORPORATE' && this.airlineCodeInbound != 'AMADEUS_CORPORATE')
		) {
			if (values.corporateName == "" || values.corporateEmail == "" || values.corporateGstNumber == "" || values.corporateMobile == "") {
				this.setStep(this.sectionIndex.GST_DETAILS)
				this.active = false;
				this.showError('warning-box','Please Add GST Details',undefined)
				this.disableBtn = false
				return false;
			}
		}
		if (this.data[0].priceType == "SPICEMAX") {
			if (!this.checkSpicemaxSeat(this.data[0].priceType, this.outboundSeletedSeatArr)) {
				this.setStep(this.sectionIndex.ADD_ONS)
				this.active = false;
				this.showError('warning-box','Seat is mandatory with spicemax fare',undefined)
				this.disableBtn = false
				this.warningMessage = "Seat is mandatory with spicemax fare";
				return false;
			}
		}
		values.isHold = isHold;
		this.isHoldOutBoundBooking = isHold;
		values.passengerName = values.passengerList[0].firstName + ' ' + values.passengerList[0].lastName;
		// values.bookingRefNo = await this.generateBookingRef();	// create booking ref number
		if(this.outBoundHasFreeMeal){
			let selectMealPerLegsCount = 0
			if(this.outboundSeletedMealArr){
			  for (let selectedSSR of this.outboundSeletedMealArr) {
				if(selectedSSR.meals && selectedSSR.meals.length){
					let mealCountPerTraveller = 0
					for (const meal of selectedSSR.meals) {
						if(meal && meal.code && meal.price >= 0){
							mealCountPerTraveller++
						}
					}
					if(mealCountPerTraveller == (this.noOfAdult+this.noOfChild)){
						selectMealPerLegsCount++
					}
				  }
					  
			  }
			}
			if(selectMealPerLegsCount < this.totalFreeMealPerLegOutBound && !this.supplierName.includes('AMADEUS_V4')){
			  this.setStep(this.sectionIndex.ADD_ONS);
			  this.activeAddonTab = 'meal'
			  this.warningMessage = "Please select free meal for all outbound sector and traveller";
			  this.warningMessageType = 'danger'
			  this.showError(
				"error-box",
				this.warningMessage,
				undefined
			  );
			  this.active = false;
			  return false
			}else{
				this.warningMessage = "";
				this.warningMessageType = ''
			}
		}
		values.bookingDate = new Date();
		if (this.isDomestic == true) {
			values.isDomestic = 'true';
		}
		else {
			values.isDomestic = 'false';
		}
		values.sCode = '';
		values.seats = this.outboundSeletedSeatArr;
		values.baggage = this.outboundSelectedBaggageArr;
		values.additionalBaggage = this.outboundSelectedAdditionalBaggageArr;
		values.ancillary = this.outboundSelectedAncillaryArr;
		values.meals = this.outboundSeletedMealArr;
		values.wheelChairs = this.outboundSelectedWheelChairArr
		values.inBoundSeats = this.inboundSelectedSeatArr
		values.inBoundBaggages = this.inboundSelectedBaggageArr
		values.inBoundAdditionalBaggage = this.inboundSelectedAdditionalBaggageArr
		values.inBoundAncillary = this.inboundSelectedAncillaryArr
		values.inBoundMeals = this.inboundSelectedMealArr
		values.inBoundWheelChairs = this.inboundSelectedWheelChairArr


		values.xlCode = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		values.passengerList = this.createPassengerData(values.passengerList, 'TICKET-');	// create passenger/pax data and added dynamic tickets for per pax
		this.data[0]['netPayable'] = this.netPayable;
		localStorage.setItem('formValue', JSON.stringify(values))
		if (checkDuplicate == true) {
			let duplicateBookingRes = {};
			if (environment.enableBooking) {
				duplicateBookingRes = await this.doDuplicateBookingCheck(values, this.data, this.outboundLimitType);
			}
			console.log('Duplicate booking check  response', duplicateBookingRes)
			if (duplicateBookingRes.hasOwnProperty('duplicate')) {
				if (duplicateBookingRes['duplicate'] == true) {
					this.duplicateBookingMsg = {
						text: duplicateBookingRes['errorMessage'],
						reference_no: duplicateBookingRes['duplicateReference'],
						direction: 'outbound',
						isHold: isHold
					}
					this.disableBtn = false
					this.active = false;
					this.openDuplicateBookingModal()
					return false
				} else {
					if (this.selectedPaymentMethod['outbound'] == 'agent-balance') {
						this.doOutBoundBooking(values, isHold)
					} else {
						this.active = false;
						this.selectedFlightDirectionForPayment = 'outbound'
						this.openPaymentInfoModal()
					}
				}
			}
		} else {
			values['forceBooking'] = forceBooking
			if (forceBooking && this.duplicateBookingMsg != undefined) {
				if (this.duplicateBookingMsg.direction == 'outbound') {
					if(this.duplicateBookingMsg != undefined && this.duplicateBookingMsg != null && this.duplicateBookingMsg.hasOwnProperty('reference_no')) {
						values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
					}
					// values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
				}
			}
			localStorage.setItem('formValue', JSON.stringify(values))
			if (this.selectedPaymentMethod['outbound'] == 'agent-balance') {
				this.doOutBoundBooking(values, isHold)
			} else {
				this.active = false;
				this.selectedFlightDirectionForPayment = 'outbound'
				this.openPaymentInfoModal()
			}
		}

	}
	private openPaymentInfoModal(): void {
		this.showPaymentInfo.nativeElement.click();
	}
	private openDuplicateBookingModal(): void {
		this.showDuplicateBooking.nativeElement.click();
	}
	private openPriceChangeModal(): void {
		this.showPriceChange.nativeElement.click();
	}
	viewBooking(reference) {
		window.open(`${environment.baseUrl}/#/booking-summary-detail;searchId=${reference}`, "_blank");
	}

	showHoldInfoFn(values, forceBooking, checkDuplicate, isHold, isInbound) {
		 
		if( 
			this.supplierName.includes('AMADEUS') && this.outboundSeletedSeatArr && this.outboundSeletedSeatArr.length>0 && 
			(
			  this.outboundSeletedSeatArr[0].seats.length> 0 ||
			  this.outboundSeletedSeatArr[0].baggage.length> 0 ||
			  this.outboundSeletedSeatArr[0].additionalBaggages.length> 0 ||
			  this.outboundSeletedSeatArr[0].ancillaries.length> 0 || 
			  this.outboundSeletedSeatArr[0].meals.length> 0 ||
			  this.outboundSeletedSeatArr[0].wheelChairs.length> 0
			)
		  ) {
			this.outBoundSsrWarningMessage = true;
		  } else {
			this.outBoundSsrWarningMessage = false;
		  }

		  if( 
			this.inWardSupplierName.includes('AMADEUS') && this.inboundSelectedSeatArr && this.inboundSelectedSeatArr.length>0 && 
			(
			  this.inboundSelectedSeatArr[0].seats.length> 0 ||
			  this.inboundSelectedSeatArr[0].baggage.length> 0 ||
			  this.inboundSelectedSeatArr[0].additionalBaggages.length> 0 ||
			  this.inboundSelectedSeatArr[0].ancillaries.length> 0 || 
			  this.inboundSelectedSeatArr[0].meals.length> 0 ||
			  this.inboundSelectedSeatArr[0].wheelChairs.length> 0
			)
		  ) {
			this.inBoundSsrWarningMessage = true;
		  } else {
			this.inBoundSsrWarningMessage = false;
		  }

		  
		this.inboundSelected = isInbound
		if (isInbound) {
			if (!this.inInBoundLCC) {
				this.openHoldInfoModal()
			}else{
				this.issueInBoundTicket(values,isHold, forceBooking, checkDuplicate )
			}
		} else {
			if (!this.isLCC) {
				this.openHoldInfoModal()
			}else{
				this.issueOutBoundTicket(values,isHold, forceBooking, checkDuplicate )
			}
		}
		
	}
	private openHoldInfoModal(): void {
		this.showHoldInfo.nativeElement.click();
	}
	setAllowHold(event,type){
		if (event.target.checked == true) {
			this.allowHold[type] = true
		}
		else {
			this.allowHold[type] = false
		}
	}

	async doOutBoundBooking(values, isHold) {
		// await this.creatingBookingRequest(values, this.data); // calling to create an live booking
		let bookingRes = await this.creatingBookingRequest(values, this.data, this.outboundLimitType,false,this.outBoundSubFareType);// calling to create an live booking
		// this.saveTravellers(values)
		this.bookingRequestService.checkifExistGST(values)
		console.log('Booking response', bookingRes)
		if (bookingRes.hasOwnProperty('duplicate')) {
			if (bookingRes['duplicate'] == true) {
				this.duplicateBookingMsg = {
					text: bookingRes['errorMessage'],
					reference_no: bookingRes['duplicateReference'],
					direction: 'outbound',
					isHold: values.isHold,
					status: bookingRes['status']
				}
				this.active = false;
				this.outBoundTicketIssueStatus = true;
				this.setStep(this.sectionIndex.PAYMENT)
				this.disableBtn = false;
				this.openDuplicateBookingModal()
				return false
			}
		}
		else if (bookingRes.hasOwnProperty('priceChanged') && bookingRes['priceChanged']) {
			this.data[0].fares = [];
			this.data[0].fares.push(bookingRes['bookingResponse'].selectFlight.selectedFare);
			this.data[0].selectedFares = bookingRes['bookingResponse'].selectFlight.selectedFare;
			this.data[0]['oldFare'] = bookingRes['bookingResponse'].selectFlight.oldFare
			if(bookingRes['bookingResponse']['selectFlight'].hasOwnProperty('segments')  && bookingRes['bookingResponse']['selectFlight']['length'] > 0) {
				this.data[0].segments = bookingRes['bookingResponse']['selectFlight']['segments'];
			}
			// fares
			localStorage.setItem('selectedOutboundFlight', JSON.stringify(this.data[0]));
			this.isIncreasedPrice = true;
			this.active = false;
			await this.loadOutboundFareData();
			this.openPriceChangeModal();
		}else if (bookingRes.hasOwnProperty('isRevertibleException') && bookingRes['isRevertibleException']) {
			this.active = false;
			this.showError('error-box',bookingRes['message'],bookingRes['messageDetails'])
		}
		else {
			this.data[0]['bookingId'] = bookingRes['bookingId']
			if (bookingRes != "" && bookingRes['bookingResponse'] != null) {
				//Save Booking in postgres sql
				//Save Booking in postgres sql
				
				if (isHold) {
					values['bookingStatus'] = "HOLD";
					this.data[0]['status'] = "HOLD";
					values.bookingInvoice = '';
					if(bookingRes["bookingResponse"]['selectFlight']['supplierInfo']) this.data[0]["hold_ttl"] = bookingRes["bookingResponse"]['selectFlight']['supplierInfo'].hold_ttl || "";
				}
				else {
					values['bookingStatus'] = "TICKETED";
					this.data[0]['status'] = "TICKETED";
					// values.bookingInvoice = await this.generateInvoiceNum(); // create invoice number
				}
				this.data[0]['bookingId'] = bookingRes['bookingResponse']['bookingId']
	
				this.data[0]['invoice_no'] = bookingRes['bookingResponse']['invoice_no']
				if (bookingRes.hasOwnProperty('supplierInfo')) {
					this.data[0]['supplierMessage'] = bookingRes['supplierInfo']
				}
				if(bookingRes.hasOwnProperty("pnr")){
					this.data[0]["pnr"] = bookingRes["pnr"]
				}
				localStorage.setItem("ONWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.data[0]));
				// localStorage.setItem("ONWARDS_BOOKING_"+bookingRes['bookingResponse'].bookingId, JSON.stringify(bookingRes['bookingResponse']));
				values.pnrNo = bookingRes['bookingId'];
				this.outBoundBookingStatus = values['bookingStatus']
				values['message'] = "Success";
				values['gdsPnr'] = bookingRes['pnr'];
				values['bookingResp'] = bookingRes['bookingResponse'];
				localStorage.setItem('outBoundRef', this.data[0]['bookingId'])
				localStorage.setItem('outBoundInvoice', this.data[0]['invoice_no'])
				localStorage.setItem('outBoundStatus', this.data[0]['status'])
				if (bookingRes['bookingResponse'].hold == true) {
					values['ticketedIssue'] = bookingRes['message']
					values['bookingStatus'] = "HOLD";
				}
				this.OnWardsBookingResJson = bookingRes['bookingResponse'];
				// this.bookingService.sendSms(this.OnWardsBookingResJson)
				this.active = false;
				this.outBoundTicketIssueStatus = true;
	
				this.setStep(this.sectionIndex.PAYMENT)
			}
			else {
				this.data[0]['status'] = "FAILED"
				localStorage.setItem("ONWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.data[0]));
				console.log("ONWARDS_BOOKING_" + this.data[0]['bookingId'])
				let searchId = this.data[0]['bookingId']
				this.router.navigate(['booking-status', { searchId }]);
				this.active = false;
			}
		}
	}

	async issueInBoundTicket(values, isHold, forceBooking, checkDuplicate) {

	
		values.hideFaresOnAirlineTicket = this.travellerForm.value.isInBoundMaskFareEnabled
		this.currentBooking = 'inbound';
		this.active = true;
		const invalid = [];
		this.messageList['messageList'] = []
		this.notificationService.showMessage(this.messageList)
		this.disableBtnInbound = true
		const controls = this.travellerForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "passengerList") {
				invalid.push(name);
			}
		}
		console.log('invalid field on submit', invalid)
		if (invalid.length > 0) {
			this.panelOpenState = true;
			this.openTravellerTab = true;
			this.setStep(this.sectionIndex.TRAVELLER_INFO)
			this.active = false;
			this.disableBtnInbound = false
			return false;
		}
		if (this.invalidCredit && !isHold) {
			this.active = false;
			this.disableBtnInbound = false
			return false
		}
		if ((this.outboundFareType == "SME" || this.inboundFareType == 'SME') && (this.data[0].supplierCode != '6E' && this.InboundData[0].supplierCode != '6E') && (this.data[0].supplierCode != '6E_T' && this.InboundData[0].supplierCode != '6E_T')) {
			if (values.corporateName == "" || values.corporateEmail == "" || values.corporateGstNumber == "" || values.corporateMobile == "") {
				this.setStep(this.sectionIndex.GST_DETAILS)
				this.active = false;
				this.showError('warning-box','Please Add GST Details',undefined)
				this.disableBtnInbound = false
				return false;
			}
		}
		if (this.InboundData[0].priceType == "SPICEMAX") {
			if (!this.checkSpicemaxSeat(this.InboundData[0].priceType, this.inboundSelectedSeatArr)) {
				this.setStep(this.sectionIndex.ADD_ONS)
				this.active = false;
				this.showError('warning-box','Seat is mandatory with spicemax fare',undefined)
				this.disableBtnInbound = false
				this.warningMessage = "Seat is mandatory with spicemax fare";
				return false;
			}
		}
		if(this.inBoundHasFreeMeal){
			let selectMealPerLegsCount = 0
			if(this.inboundSelectedMealArr){
			  for (let selectedSSR of this.inboundSelectedMealArr) {
				if(selectedSSR.meals && selectedSSR.meals.length){
					let mealCountPerTraveller = 0
					for (const meal of selectedSSR.meals) {
						if(meal && meal.code && meal.price >= 0){
							mealCountPerTraveller++
						}
					}
					if(mealCountPerTraveller == (this.noOfAdult+this.noOfChild)){
						selectMealPerLegsCount++
					}
				  }
					  
			  }
			}
			if(selectMealPerLegsCount < this.totalFreeMealPerLegInBound && !this.supplierName.includes('AMADEUS_V4')){
			  this.setStep(this.sectionIndex.ADD_ONS);
			  this.activeAddonTab = 'meal'
			  this.warningMessage = "Please select free meal for all inbound sector and traveller";
			  this.warningMessageType = 'danger'
			  this.showError(
				"error-box",
				this.warningMessage,
				undefined
			  );
			  this.active = false;
			  return false
			}
		}
		values.isHold = isHold;
		this.isHoldInBoundBooking = isHold;
		values.passengerName = values.passengerList[0].firstName + ' ' + values.passengerList[0].lastName;
		values.bookingDate = new Date();
		if (this.isDomestic == true) {
			values.isDomestic = 'true';
		}
		else {
			values.isDomestic = 'false';
		}
		values.sCode = '';
		values.seats = this.inboundSelectedSeatArr;
		values.baggage = this.inboundSelectedBaggageArr;
		values.meals = this.inboundSelectedMealArr;
		values.wheelChairs = this.inboundSelectedWheelChairArr
		values.additionalBaggage = this.inboundSelectedAdditionalBaggageArr;
		values.ancillary = this.inboundSelectedAncillaryArr;
		values.xlCode = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		this.InboundData[0]['netPayable'] = this.netPayableInbound;
		values.passengerList = this.createPassengerData(values.passengerList, 'TICKET-');	// create passenger/pax data and added dynamic tickets for per pax
		localStorage.setItem('formValue', JSON.stringify(values))
		if (checkDuplicate == true) {
			let duplicateBookingRes = {};
			if (environment.enableBooking) {
				duplicateBookingRes = await this.doDuplicateBookingCheck(values, this.InboundData, this.inboundLimitType);
			}
			console.log('Duplicate booking check  response', duplicateBookingRes)
			if (duplicateBookingRes.hasOwnProperty('duplicate')) {
				if (duplicateBookingRes['duplicate'] == true) {
					this.duplicateBookingMsg = {
						text: duplicateBookingRes['errorMessage'],
						reference_no: duplicateBookingRes['duplicateReference'],
						direction: 'inbound',
						isHold: isHold
					}
					this.active = false;
					this.disableBtnInbound = false
					this.disableBtn = false;
					this.openDuplicateBookingModal()
					return false
				} else {
					if (this.selectedPaymentMethod['inbound'] == 'agent-balance') {
						this.doInBoundBooking(values, isHold)
					} else {
						this.active = false;
						this.selectedFlightDirectionForPayment = 'inbound'
						this.openPaymentInfoModal()
					}
				}
			}
		} else {
			values['forceBooking'] = forceBooking
			if (forceBooking && this.duplicateBookingMsg != undefined) {
				if (this.duplicateBookingMsg.direction == 'inbound') {
					if(this.duplicateBookingMsg != undefined && this.duplicateBookingMsg != null && this.duplicateBookingMsg.hasOwnProperty('reference_no')) {
						values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
					}
					// values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
				}
			}
			localStorage.setItem('formValue', JSON.stringify(values))
			if (this.selectedPaymentMethod['inbound'] == 'agent-balance') {
				this.doInBoundBooking(values, isHold)
			} else {
				this.active = false;
				this.selectedFlightDirectionForPayment = 'inbound'
				this.openPaymentInfoModal()
			}
		}

	}
	async doInBoundBooking(values: any, isHold: any) {
		// await this.creatingBookingRequest(values, this.InboundData); // calling to create an live booking
		let bookingRes = await this.creatingBookingRequest(values, this.InboundData, this.inboundLimitType,true ,this.inBoundSubFareType); // calling to create an live booking
		if (bookingRes.hasOwnProperty('duplicate')) {
			if (bookingRes['duplicate'] == true) {
				this.duplicateBookingMsg = {
					text: bookingRes['errorMessage'],
					reference_no: bookingRes['duplicateReference'],
					direction: 'inbound',
					isHold: values.isHold,
					status: bookingRes['status']
				}
				this.active = false;
				this.disableBtn = false;
				this.openDuplicateBookingModal()
				return false
			}
		}

		else if (bookingRes.hasOwnProperty('priceChanged') && bookingRes['priceChanged']) {
			this.InboundData[0].fares = [];
			this.InboundData[0].fares.push(bookingRes['bookingResponse'].selectFlight.selectedFare);
			this.InboundData[0].selectedFaresInbound = bookingRes['bookingResponse'].selectFlight.selectedFare;
			this.InboundData[0]['oldFare'] = bookingRes['bookingResponse'].selectFlight.oldFare
			if(bookingRes['bookingResponse']['selectFlight'].hasOwnProperty('segments')  && bookingRes['bookingResponse']['selectFlight']['length'] > 0) {
				this.InboundData[0].segments = bookingRes['bookingResponse']['selectFlight']['segments'];
			}
			// fares
			localStorage.setItem('selectedInboundFlight', JSON.stringify(this.InboundData[0]));
			this.isIncreasedPrice = true;
			this.active = false;
			await this.loadInboundFareData();
			this.openPriceChangeModal()
		}else if (bookingRes.hasOwnProperty('isRevertibleException') && bookingRes['isRevertibleException']) {
			this.active = false;
			this.showError('error-box',bookingRes['message'],bookingRes['messageDetails'])
		}
		else{

			this.InboundData[0]['bookingId'] = bookingRes['bookingId']
			if (bookingRes != "" && bookingRes['bookingResponse'] != null) {
				//Save Booking in postgres sql
				if (isHold) {
					values['bookingStatus'] = "HOLD";
					this.InboundData[0]['status'] = "HOLD";
					values.bookingInvoice = '';
					if(bookingRes["bookingResponse"]['selectFlight']['supplierInfo']) this.InboundData[0]["hold_ttl"] = bookingRes["bookingResponse"]['selectFlight']['supplierInfo'].hold_ttl || "";
				}
				else {
					values['bookingStatus'] = "TICKETED";
					this.InboundData[0]['status'] = "TICKETED";
					// values.bookingInvoice = await this.generateInvoiceNum(); // create invoice number
				}
				values.pnrNo = bookingRes['bookingId'];
				values['message'] = "Success";
				values['gdsPnr'] = bookingRes['pnr'];
				if (bookingRes['bookingResponse'].hold == true) {
					values['ticketedIssue'] = bookingRes['message']
					values['bookingStatus'] = "HOLD";
				}
				values['bookingResp'] = bookingRes['bookingResponse'];
				// let bookingResJson = this.bookingRequestService.getBookingRequest(values, this.request, this.inWardSupplierName, this.inInBoundLCC);
				this.InboundData[0]['bookingId'] = bookingRes['bookingResponse']['bookingId']
				this.InboundData[0]['invoice_no'] = bookingRes['bookingResponse']['invoice_no']
				// localStorage.setItem("INWARDS_BOOKING_" + bookingRes['bookingResponse']['bookingId'], JSON.stringify(this.InboundData[0]));
				// localStorage.setItem("ONWARDS_BOOKING_" + bookingRes['bookingResponse']['bookingId'], JSON.stringify(this.data));
				if (localStorage.getItem('outBoundRef') != undefined) {
					this.data[0]['bookingId'] = localStorage.getItem('outBoundRef')
					this.data[0]['invoice_no'] = localStorage.getItem('outBoundInvoice')
					this.data[0]['status'] = localStorage.getItem('outBoundStatus')
				}
				if (bookingRes.hasOwnProperty('supplierInfo')) {
					this.InboundData[0]['supplierMessage'] = bookingRes['supplierInfo']
				}
				if(bookingRes.hasOwnProperty("pnr")){
					this.InboundData[0]["pnr"] = bookingRes["pnr"]
				}
				 
				localStorage.setItem("ONWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.data[0]));
				localStorage.setItem("INWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.InboundData[0]));
				let searchId = this.data[0]['bookingId']
				localStorage.removeItem('outBoundRef')
				localStorage.removeItem('formValue')
				this.router.navigate(['booking-status', { searchId }]);
				this.active = false;
			}
			else {
				this.InboundData[0]['status'] = "FAILED"
				console.log("ONWARDS_BOOKING_" + this.data[0]['bookingId'])
				localStorage.setItem("ONWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.data[0]));
				localStorage.setItem("INWARDS_BOOKING_" + this.data[0]['bookingId'], JSON.stringify(this.InboundData[0]));
	
				console.log("Booking Failed");
				let searchId = this.data[0]['bookingId']
				this.router.navigate(['booking-status', { searchId }]);
	
	
			}
		}
	}
	saveTravellers(values) {
		values.userDetails = JSON.parse(localStorage.getItem('user_details'));
		values.travellers = this.travellerForm.controls.passengerList.value;
		for (let i = 0; i < values.travellers.length; i++) {
			const element = values.travellers[i];
			if (i == 0) {
				element['mobile_number'] = this.travellerForm.controls.mobile.value
			}
			else {
				element['mobile_number'] = ''
			}
			this.checkifExist(element)
		}
	}
	
	doDuplicateBookingCheck(value, flightData, limitType) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.bookingRequest(value, flightData, limitType, false,'');
			this.request = obj;
			this.postService.postMethod(`${environment.url}/book/checkduplicate`, obj).subscribe(res => {
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				console.log("Error in booking : ", err)
				if (err.hasOwnProperty('error')) {
					if (err.error.hasOwnProperty('duplicate')) {
						resolve(err.error)
					}
				} else {
					resolve('');

				}
			})

		})
	}

	creatingBookingRequest(value, flightData, limitType,isReturn,subFareType) {
		return new Promise((resolve, reject) => {
			value['total_flight_count']=2
			value['current_flight_index']=1
			if(isReturn){
				value['current_flight_index']=2
				let parent_booking_ref = this.data[0]['bookingId']
				value['parent_booking_ref'] = parent_booking_ref
			}
			value['isInsuranceRequested'] = this.isInsuranceSelected
			value["insuranceTravellerList"]= this.insuranceTravellerList
			this.data[0].isInsuranceSelected = this.isInsuranceSelected
			this.InboundData[0].isInsuranceSelected = this.isInsuranceSelected
			
			let obj = this.bookingServiceRequest.bookingRequest(value, flightData, limitType,false,subFareType);
			this.request = obj;
			if(environment.name == 'prod'){
				try {
					let type = "new_booking";
					let message = `New booking request.`;
					if (this.request.hideFaresOnAirlineTicket == true) {
						type = "fare_masking";
						message = "New booking request with fare masking";
					}
					if (this.request.hold == true) {
						type = `hold_booking`;
						message = "Hold booking request.";
						if (type == "fare_masking") {
							type = `hold_fare_masking`;
							message = "New hold booking request with fare masking";
						}
					}
					if(value.isInsuranceRequested){
						type += `_with_insurance`;
					}
					this.commonService.addUserBookingActivity(type, message, "", "");
				} catch (error) {}
			}
			// let mockUl = `https://c8ba32cd-3151-4106-8761-c5c4a95e258b.mock.pstmn.io/failBaggage`;
			// this.postService.postMethod(`${mockUl}`, obj).subscribe(res => {
			this.postService.postMethod(`${environment.url}/book/test`, obj).subscribe(res => {
				if (res != "") {
					// let data = JSON.parse(res['_body']);
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve(err['error']);
			})

		})
	}
	// function to view offer
	viewOfffer() {
		if (this.isOffer) {
			this.isOffer = false;
		}
		else {
			this.isOffer = true;
		}
	}
	// traveller form validation
	get email() {
		return this.travellerForm.controls.email;
	}
	get mobile() {
		return this.travellerForm.controls.mobile;
	}
	// create outbound data request
	makeOutboundData() {
		try {
			this.data.push(JSON.parse(localStorage.getItem('selectedOutboundFlight')));
			if(
				this.data[0] && 
				this.data[0].fares[0] && 
				this.data[0].fares[0].supplierInfo && 
				this.data[0].fares[0].supplierInfo.subPriceType){
				this.outBoundSubFareType = this.data[0].fares[0].supplierInfo.subPriceType
			}

			this.flightRequest = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'));
			this.outBoundDirection = this.data[0].direction;
			this.loadOutboundFareData();
			this.legs = this.data[0].segments[0].legs;
			this.airlineCode = this.data[0].airline.code;
			// passport expiry min  date
			this.minDate = new Date(this.legs[0].departureDateTime)
			let expiry_month = this.minDate.getMonth() + 6
			this.minDate = new Date(this.minDate.setMonth(expiry_month))

			this.isLCC = this.travellerService.checkLCC(this.airlineCode);
			if (this.isLCC) {
				let sKeys = []
				let outBoundskey = {
					"fareSellKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.FareSellKey : "",
					"journeyKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.JourneySellKey : ""
				}
				sKeys.push(outBoundskey)
				this.data[0].supplierInfo.sKeys = sKeys
			}
			if (this.isLCC) {
				this.getOutBoundBaggage(this.data);
			}
			this.isDomestic = this.flightRequest.domestic;
			this.departure = this.flightRequest.destinationDetails.code;
			this.arrival = this.flightRequest.originDetails.code;
			let d = new Date(this.flightRequest.journeyStartDate);
			this.date = d.toDateString();
			this.noOfAdult = this.flightRequest.noOfAdults;
			this.noOfChild = this.flightRequest.noOfChild;
			this.noOfInfant = this.flightRequest.noOfInfants;
			this.totalPassengerCount = Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
			for (let i = 1; i <= this.flightRequest.noOfAdults; i++) {
				this.passengerList = this.travellerForm.get('passengerList') as FormArray;
				this.passengerList.push(this.buildItem('Adult'));
				this.setValidator(this.passengerList.length - 1)
			}
			for (let i = 1; i <= this.flightRequest.noOfChild; i++) {
				this.passengerList = this.travellerForm.get('passengerList') as FormArray;
				this.passengerList.push(this.buildItem('Child'));
				this.setValidator(this.passengerList.length - 1)
			}
			for (let i = 1; i <= this.flightRequest.noOfInfants; i++) {
				this.passengerList = this.travellerForm.get('passengerList') as FormArray;
				this.passengerList.push(this.buildItem('Infant'));
				this.setValidator(this.passengerList.length - 1)
			}
			let paxDiscountCode = null;
			if (this.selectedFares[0].supplierInfo != null) {
				paxDiscountCode = this.selectedFares[0].supplierInfo.FareBasisCode;
			}
			if (this.flightRequest.noOfAdults > 0) {
				let obj = {
					"paxCount": this.flightRequest.noOfAdults,
					"paxDiscountCode": paxDiscountCode,
					"paxType": "ADT"
				}
				this.totalPaxArr.push(obj);
			}
			if (this.flightRequest.noOfChild > 0) {
				let obj = {
					"paxCount": this.flightRequest.noOfChild,
					"paxDiscountCode": paxDiscountCode,
					"paxType": "CHD"
				}
				this.totalPaxArr.push(obj);
			}
			if (this.flightRequest.noOfInfants > 0) {
				let obj = {
					"paxCount": this.flightRequest.noOfInfants,
					"paxDiscountCode": paxDiscountCode,
					"paxType": "INF"
				}
				this.totalPaxArr.push(obj);
			}


			this.bookingRequestService.getCreditLimit(this.isLCC, this.netPayable, this.flightRequest.affiliate, 'outbound');
			if (this.data[0].supplierCode == "6E") {
				this.supplierName = "INDIGO";
			}
			else if (this.data[0].supplierCode == "SG") {
				this.supplierName = "SPICEJET";
			}
			else if (this.data[0].supplierCode == "G8") {
				this.supplierName = "GO_AIR";
			}
			else {
				this.supplierName = this.data[0].supplierCode;
			}

			if(this.data[0].fares[0].supplierInfo && this.data[0].fares[0].supplierInfo.SUPPLIER == 'gfs') {
				this.isGfsSupplier = true;
			}
			
			let hideHoldButton = this.checkHoldButtonForGfs()

			this.showHoldOutBound = true
			this.showAddons = true
			// date diff in minutes
			this.date_diff = this.commonService.timeDuration2(new Date(this.current_time), new Date(this.legs[0].departureDateTime.split('+')[0]))
			if (this.date_diff < 4320 && (this.data[0].airline.code == '6E')) {
				this.showHoldOutBound = false
			}
			if (this.date_diff < 240 && (this.data[0].airline.code == 'G8' || this.data[0].airline.code == 'SG')) {
				this.showHoldOutBound = false
			}
			if (this.date_diff < 120 && (this.data[0].airline.code == 'G8' || this.data[0].airline.code == 'SG' || this.data[0].airline.code == '6E')) {
				this.showAddons = false
			}
			if (this.data[0].supplierCode == 'airasia'  || this.data[0].supplierCode == "airasia_v2" || this.data[0].airline.code == "QP" || hideHoldButton === true) {
				this.showHoldOutBound = false
			}

			// Get fare rule 
			let fareRuleObj = {
				"supplierName": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.SUPPLIER : "",
				"classOfService": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.ClassOfService : "",
				"fareBasisCode": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.FareBasisCode : "",
				"ruleNumber": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.RuleNumber : "",
				"signature": this.data[0].fares[0].supplierInfo ? this.data[0].supplierInfo.Signature : "",
				"supplierInfo": this.data[0].fares[0].supplierInfo
			}

			if (this.data[0].supplierCode == "SABRE") {
				fareRuleObj.supplierName = this.data[0].supplierCode;
				let supplierInfo = {
					"Origin": this.data[0].segments[0].originAirport.code,
					"Destination": this.data[0].segments[0].destinationAirport.code,
					"TravelDate": this.data[0].segments[0].legs[0].supplierInfo.DepartureDateTime
				}
				fareRuleObj["supplierInfo"] = supplierInfo;
				fareRuleObj["fareBasisCode"] = this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.FareBasisCode : "";
			}
			else if (this.data[0].supplierCode.includes('AMADEUS_V4')) {
				let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest)
				fareRuleObj['supplierName'] = this.data[0].supplierCode
				let supplierInfo = {
					"travellerInfo": {
						"ADT": flightReq['noOfAdults'],
						"CHD": flightReq['noOfChild'],
						"INF": flightReq['noOfInfants']
					},
					"sectors": [
						{
							"departureDateTime": this.data[0].segments[0].legs[0].departureDateTime,
							"departureCityCode": this.data[0].segments[0].legs[0].originAirport['code'],
							"arrivalCityCode": this.data[0].segments[0].legs[0].destinationAirport['code'],
							"airlineCode": this.data[0].segments[0].legs[0].operatingAirlineCode,
							"flightNum": this.data[0].segments[0].legs[0].flightNumber,
							"bookingClass": this.data[0].segments[0].legs[0].cabinClass
						}
					]
				}
				fareRuleObj["supplierInfo"] = supplierInfo;
			}
			this.getOutBoundFareRule(fareRuleObj);
			this.getInsuranceData(this.flightRequest)

			if((this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')) == false){
				for(let i=0;i<this.DynamicFormControls.controls.length;i++){
				   this.DynamicFormControls.controls[i]['controls']['title'].setValidators([Validators.required])
				}
			}
			  
		} catch (error) {
			console.log('Error While making outbound flight : ', error)
		}
	}

	loadOutboundFareData() {

		const localThis = this;

		this.selectedFares = [];
		let loadRepricing = false;
		if(this.data[0].selectedFares != undefined && this.data[0].selectedFares != null) {
			this.selectedFares.push(this.data[0].selectedFares)
			loadRepricing = true;
		}
		else{
			this.selectedFares = this.data[0].fares.filter(option => {
				return option.priceType == localThis.data[0].priceType;
			})
		}
		this.total = this.data[0].selFlightPrice;
		this.totalBookingPrice += this.total;
		this.supplierType = this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo['SUPPLIER'] : "";
		this.netCommission = this.selectedFares[0].fareComponents.COMMISSION;
		this.tds = this.selectedFares[0].fareComponents.TDS;
		this.data[0].fares = this.selectedFares;
		this.baseFare = this.selectedFares[0].base;
		this.totalFare = this.selectedFares[0].total;
		this.outboundFareType = this.selectedFares[0].priceType;
		this.totaltax = (this.totalFare - this.baseFare) + this.adultTaxFare + this.childTaxFare;
		this.calculateNetPayable(false, loadRepricing);
		if (this.selectedFares[0].travellerFares.ADT) {
			this.adultBaseFare = this.selectedFares[0].travellerFares.ADT.base;
			this.adultTotalFare = this.selectedFares[0].travellerFares.ADT.total;
			this.adultTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.CHD) {
			this.childBaseFare = this.selectedFares[0].travellerFares.CHD.base;
			this.childTotalFare = this.selectedFares[0].travellerFares.CHD.total;
			this.childTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.INF) {
			this.infBaseFare = this.selectedFares[0].travellerFares.INF.base;
			this.infTotalFare = this.selectedFares[0].travellerFares.INF.total;
			this.infTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
		}

		// if(loadRepricing) {
		// 	this.openPriceChangeModal();
		// }
	}

	getOutBoundFareRule(fareRuleObj) {
		let fareRule = ""
		this.postService.postMethod(`${environment.url}/getFareRule`, fareRuleObj).subscribe(data => {
			try {
				if (data != "" && data != null) {
					fareRule = data['fareRule'];
				}
				else {
					fareRule = "No Fare Rule Found";
				}
				this.data[0]['fareRule'] = fareRule
				console.log('fareRule ', data)
			}
			catch (Exception) {
				console.log(Exception)
			}


		}, error => {
			fareRule = "No Fare Rule Found";
			this.data[0]['fareRule'] = fareRule
		})
	}
	// create inbound data request
	makeInboundData() {
		this.InboundData.push(JSON.parse(localStorage.getItem('selectedInboundFlight')));
		if(
			this.InboundData[0] && 
			this.InboundData[0].fares[0] && 
			this.InboundData[0].fares[0].supplierInfo && 
			this.InboundData[0].fares[0].supplierInfo.subPriceType){
			this.inBoundSubFareType = this.InboundData[0].fares[0].supplierInfo.subPriceType
		}

		this.inBoundDirection = this.InboundData[0].direction;
		this.InwardflightRequest = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'));
		this.loadInboundFareData(); // function for binding inbound fares
		this.Inboundlegs = this.InboundData[0].segments[0].legs;
		this.airlineCodeInbound = this.InboundData[0].airline.code;

		this.inInBoundLCC = this.travellerService.checkLCC(this.airlineCodeInbound);
		if (this.inInBoundLCC) {
			let sKeys = []
			let inBoundskey = {
				"fareSellKey": this.selectedFaresInbound[0].supplierInfo ? this.selectedFaresInbound[0].supplierInfo.FareSellKey : "",
				"journeyKey": this.selectedFaresInbound[0].supplierInfo ? this.selectedFaresInbound[0].supplierInfo.JourneySellKey : ""
			}
			sKeys.push(inBoundskey)
			this.InboundData[0].supplierInfo.sKeys = sKeys
		}
		if (this.inInBoundLCC) {
			this.getInboundBaggage(this.InboundData);
		}


		this.isDomestic = this.InwardflightRequest.domestic;
		this.departureInboud = this.InwardflightRequest.destinationDetails.code;
		this.arrivalInbound = this.InwardflightRequest.originDetails.code;
		let d = new Date(this.Inboundlegs[0].departureDateTime);
		this.dateInbound = d.toDateString();
		this.noOfAdultInbound = this.InwardflightRequest.noOfAdults;
		this.noOfChildInbound = this.InwardflightRequest.noOfChild;
		this.noOfInfantInbound = this.InwardflightRequest.noOfInfants;
		this.totalPassengerCount = Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
		for (let i = 0; i < this.passengerList.length; i++) {
			this.setValidator(i)
		}



		this.bookingRequestService.getCreditLimit(this.inInBoundLCC, this.netPayableInbound, this.flightRequest.affiliate, 'inbound');
		if (this.InboundData[0].supplierCode == "6E") {
			this.inWardSupplierName = "INDIGO";
		}
		else if (this.InboundData[0].supplierCode == "SG") {
			this.inWardSupplierName = "SPICEJET";
		}
		else if (this.InboundData[0].supplierCode == "G8") {
			this.inWardSupplierName = "GO_AIR";
		}
		else {
			this.inWardSupplierName = this.InboundData[0].supplierCode;
		}

		if(this.InboundData[0].fares[0].supplierInfo && this.InboundData[0].fares[0].supplierInfo.SUPPLIER == 'gfs') {
			this.isGfsSupplier = true;
		}

		let hideHoldButton = this.checkHoldButtonForGfs()
		
		this.showHoldInBound = true
		// date diff in minutes
		this.date_diffInbound = this.commonService.timeDuration2(new Date(this.current_time), new Date(this.Inboundlegs[0].departureDateTime.split('+')[0]))
		if (this.date_diffInbound < 240 && (this.InboundData[0].airline.code == '6E')) {
			this.showHoldInBound = false
		}
		if (this.date_diffInbound < 4320 && (this.InboundData[0].airline.code == 'G8' || this.InboundData[0].airline.code == 'SG')) {
			this.showHoldInBound = false
		}
		if (this.InboundData[0].supplierCode == 'airasia' || this.InboundData[0].supplierCode == 'airasia_v2' || this.InboundData[0].airline.code == "QP" || hideHoldButton === true) {
			this.showHoldInBound = false
		}
		console.log('Time diff in minutes ', this.date_diffInbound)

		// Get fare rule
		let fareRuleObj = {
			"supplierName": this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].fares[0].supplierInfo.SUPPLIER : "",
			"classOfService": this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].fares[0].supplierInfo.ClassOfService : "",
			"fareBasisCode": this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].fares[0].supplierInfo.FareBasisCode : "",
			"ruleNumber": this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].fares[0].supplierInfo.RuleNumber : "",
			"signature": this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].supplierInfo.Signature : "",
			"supplierInfo": this.InboundData[0].fares[0].supplierInfo
		}

		if (this.InboundData[0].supplierCode == "SABRE") {
			fareRuleObj.supplierName = this.InboundData[0].supplierCode;
			let supplierInfo = {
				"Origin": this.InboundData[0].segments[0].originAirport.code,
				"Destination": this.InboundData[0].segments[0].destinationAirport.code,
				"TravelDate": this.InboundData[0].segments[0].legs[0].supplierInfo.DepartureDateTime
			}
			fareRuleObj["supplierInfo"] = supplierInfo;
			fareRuleObj["fareBasisCode"] = this.InboundData[0].fares[0].supplierInfo ? this.InboundData[0].fares[0].supplierInfo.FareBasisCode : "";
		}
		else if (this.InboundData[0].supplierCode.includes('AMADEUS_V4')) {
			let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest)
			fareRuleObj['supplierName'] = this.InboundData[0].supplierCode
			let supplierInfo = {
				"travellerInfo": {
					"ADT": flightReq['noOfAdults'],
					"CHD": flightReq['noOfChild'],
					"INF": flightReq['noOfInfants']
				},
				"sectors": [
					{
						"departureDateTime": this.InboundData[0].segments[0].legs[0].departureDateTime,
						"departureCityCode": this.InboundData[0].segments[0].legs[0].originAirport['code'],
						"arrivalCityCode": this.InboundData[0].segments[0].legs[0].destinationAirport['code'],
						"airlineCode": this.InboundData[0].segments[0].legs[0].operatingAirlineCode,
						"flightNum": this.InboundData[0].segments[0].legs[0].flightNumber,
						"bookingClass": this.InboundData[0].segments[0].legs[0].cabinClass
					}
				]
			}
			fareRuleObj["supplierInfo"] = supplierInfo;
		}
		this.getInboundFareRule(fareRuleObj);

		if((this.inWardSupplierName == 'AMADEUS_V4' || this.inWardSupplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')) == false){
			for(let i=0;i<this.DynamicFormControls.controls.length;i++){
			   this.DynamicFormControls.controls[i]['controls']['title'].setValidators([Validators.required])
			}
		}
	}

	loadInboundFareData() {

		const localThis = this;
		this.totalInbound = this.InboundData[0].selFlightPrice;

		this.selectedFaresInbound = [];
		let loadRepricing = false;
		if(this.InboundData[0].selectedFaresInbound != undefined && this.InboundData[0].selectedFaresInbound != null) {
			this.selectedFaresInbound.push(this.InboundData[0].selectedFaresInbound)
			loadRepricing = true;
		}
		else{
			this.selectedFaresInbound = this.InboundData[0].fares.filter(option => {
				return option.priceType == localThis.InboundData[0].priceType;
			})
		}

		this.InboundSupplierType = this.selectedFaresInbound[0].supplierInfo ? this.selectedFaresInbound[0].supplierInfo['SUPPLIER'] : "";
		this.netCommissionInbound = this.selectedFaresInbound[0].fareComponents.COMMISSION;
		this.tdsInbound = this.selectedFaresInbound[0].fareComponents.TDS;
		this.InboundData[0].fares = this.selectedFaresInbound;
		this.totalBookingPrice += this.InboundData[0].selFlightPrice;
		this.totalInbound = this.InboundData[0].selFlightPrice;
		this.inboundFareType = this.selectedFaresInbound[0].priceType;
		this.totaltaxInbound = (this.totalFareInbound - this.baseFareInbound) + this.adultTaxFareInbound + this.childTaxFareInbound;
		this.baseFareInbound = this.selectedFaresInbound[0].base;
		this.totalFareInbound = this.selectedFaresInbound[0].total;
		this.calculateNetPayable(true, loadRepricing);

		if (this.selectedFaresInbound[0].travellerFares.ADT) {
			this.adultBaseFareInbound = this.selectedFaresInbound[0].travellerFares.ADT.base;
			this.adultTotalFareInbound = this.selectedFaresInbound[0].travellerFares.ADT.total;
			this.adultTaxFareInbound = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)

		}
		if (this.selectedFaresInbound[0].travellerFares.CHD) {
			this.childBaseFareInbound = this.selectedFaresInbound[0].travellerFares.CHD.base;
			this.childTotalFareInbound = this.selectedFaresInbound[0].travellerFares.CHD.total;
			this.childTaxFareInbound = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)

		}
		if (this.selectedFaresInbound[0].travellerFares.INF) {
			this.infBaseFareInbound = this.selectedFaresInbound[0].travellerFares.INF.base;
			this.infTotalFareInbound = this.selectedFaresInbound[0].travellerFares.INF.total;
			this.infTaxFareInbound = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)

		}
		
		// this.openDuplicateBookingModal();

		// if(loadRepricing) {
		// 	this.openPriceChangeModal();
		// }
	}

	getInboundFareRule(fareRuleObj) {
		let fareRule = ""
		this.postService.postMethod(`${environment.url}/getFareRule`, fareRuleObj).subscribe(data => {
			try {
				if (data != "" && data != null) {
					fareRule = data['fareRule'];
				}
				else {
					fareRule = "No Fare Rule Found";
				}
				this.InboundData[0]['fareRule'] = fareRule
				console.log('fareRule ', data)
			}
			catch (Exception) {
				console.log(Exception)
			}


		}, error => {
			fareRule = "No Fare Rule Found";
			this.data[0]['fareRule'] = fareRule
		})
	}


	// expansion panel for stepper
	async setStep(index) {
		console.log("before " + this.activeStep)
		this.activeStep = index;
		window.scroll(0, 0)
		if(index==this.sectionIndex.ADD_ONS){
			this.toggleAddon = false
			this.toggleAddonSideBar()
		}else{
			this.toggleAddon = true
			this.toggleAddonSideBar()
		}
		if (index != this.sectionIndex.TRAVELLER_INFO && index != this.sectionIndex.FLIGHT_DETAIL) {
			const invalid = [];
			if (index == this.sectionIndex.PERSONAL_INFO) {
				this.paxSubmitted = true;
				const controls = this.travellerForm.controls.passengerList['controls'];
				for (let i = 0; i < controls.length; i++) {
					for (const name in controls[i].controls) {
						if (controls[i].controls[name].invalid) {
							invalid.push(name);
						  }				
					}
				}

				if (invalid.length > 0) {
					console.log(invalid)
					this.setStep(this.sectionIndex.TRAVELLER_INFO)
				}
				else {
					let requestPaload:any = {}
					this.setPersonalDetails()
					if (!this.outBoundSsrRequest) {
						let requestData = await this.returnBookingService.bookingRequest(this.travellerForm.value, this.data, this.outboundLimitType,true);
						this.totalFreeMealPerLegOutBound = 0
						this.getSSR(requestData, false);
						this.outBoundSsrRequest = true;
						requestPaload["travellers"] = requestData.travellers
						requestPaload["segments"] = requestData.selectFlight["segments"]
					}
					if (!this.inboundSsrRequest) {
						let returnRequestData = await this.returnBookingService.bookingRequest(this.travellerForm.value, this.InboundData, this.inboundLimitType,true);
						if(!requestPaload.hasOwnProperty("travellers")){
							requestPaload["travellers"] = returnRequestData.travellers
						}

						if(requestPaload.hasOwnProperty("segments")){
							requestPaload["segments"] = requestPaload["segments"].concat(returnRequestData.selectFlight["segments"])
						}else{
							requestPaload["segments"] = returnRequestData.selectFlight["segments"]
						}
						const localThis = this;
						this.inboundSsrRequest = true;
						setTimeout(function () {
							this.totalFreeMealPerLegInBound = 0
							localThis.getSSR(returnRequestData, true)
						}, 5000)
					}

					if(requestPaload.hasOwnProperty("travellers") && requestPaload.hasOwnProperty("segments")){
				    	this.initializeSelectedSsr(requestPaload)

					}


					

					// this.getSSR(returnRequestData, true)
				}

			}
			if (index == this.sectionIndex.GST_DETAILS) {
				const invalid = [];
				this.contactSubmitted = true
				const controls = this.travellerForm.controls;
				for (const name in controls) {
					if (controls[name].invalid && name != "passengerList") {
						invalid.push(name);
					}
				}
				if (invalid.length > 0 && !invalid.includes('corporateEmail')) {
					this.setStep(this.sectionIndex.PERSONAL_INFO)
				}
			}
			if (index == this.sectionIndex.ADD_ONS) {
				const invalid = [];
				this.contactSubmitted = true
				const controls = this.travellerForm.controls;
				for (const name in controls) {
					if (controls[name].invalid && name != "passengerList") {
						invalid.push(name);
					}
				}
				console.log('invalid field in step 3', invalid)
				if (invalid.length > 0 && invalid.includes('corporateEmail')) {
					this.setStep(this.sectionIndex.GST_DETAILS)
				}
			}
			if (index == this.sectionIndex.PAYMENT) {
				 
				this.showSelectedSeatError = false;

				try {
					if (this.outboundSeletedSeatArr != undefined && this.seatMapArr && this.seatMapArr.length > 0) {
						for (const key in this.seatMapArr) {
							if (Object.prototype.hasOwnProperty.call(this.outboundSeletedSeatArr, key)) {
								const selectedSeat = this.outboundSeletedSeatArr[key];
								if (selectedSeat) {
									if ((selectedSeat.seats == null) || (selectedSeat.seats && selectedSeat.seats.length > 0 && selectedSeat.seats.length  != (this.noOfAdult+this.noOfChild))) {
										this.showSelectedSeatError = true;
										this.setStep(this.sectionIndex.ADD_ONS)
										return false
									}
								}
		
							}
						}
					}
					if ((this.outboundSeletedSeatArr != undefined && this.inboundSelectedSeatArr == undefined) 
							|| 
						this.outboundSeletedSeatArr == undefined && this.inboundSelectedSeatArr != undefined) {
						this.showSelectedSeatError = true;
						this.setStep(this.sectionIndex.ADD_ONS)
						return false
					}
					if (this.inboundSelectedSeatArr != undefined && this.InBoundSeatMapArr && this.InBoundSeatMapArr.length > 0) {
						for (const key in this.InBoundSeatMapArr) {
							if (Object.prototype.hasOwnProperty.call(this.inboundSelectedSeatArr, key)) {
								const selectedSeat = this.inboundSelectedSeatArr[key];
								if (selectedSeat) {
									if ((selectedSeat.seats == null) || (selectedSeat.seats && selectedSeat.seats.length > 0 && selectedSeat.seats.length  != (this.noOfAdult+this.noOfChild))) {
										this.showSelectedSeatError = true;
										this.setStep(this.sectionIndex.ADD_ONS)
										return false
									}
								}
		
							}
						}
					}
				} catch (error) {
					
				}
			}

		}
		else if (index == 3) {
			const invalid = [];
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid) {
					invalid.push(name);
				}
			}
			if (invalid.length > 0) {
				this.setStep(this.sectionIndex.TRAVELLER_INFO)
			}
		}
		console.log("index value : " + this.activeStep)
	}


	// create passenger data object for booking request
	createPassengerData(passengerList, ticketNo) {
		return passengerList.map((v, i) => {
			let count = i + 1;
			v.ticketNo = ticketNo + count;
			return v;
		})
	}
	// create after booking request
	// traveller form validations
	get corporateEmail() {
		return this.travellerForm.controls.corporateEmail;
	}
	get corporateMobile() {
		return this.travellerForm.controls.corporateMobile;
	}
	// create dummy object for adult
	bindDummyAdultArr(value) {
		this.dummyAdultNameArr = [];
		this.travellerForm.controls.passengerList.value.forEach(el => {
			if (el.passengerType == 'Adult') {
				this.dummyAdultNameArr.push(el.firstName);
			}
		});
	}
	// function to revert back into search form page
	onFinished() {
		let searchId = localStorage.getItem('flightSearchId');
		this.router.navigate(['pages/flight', { searchId }]);
	}

	getBaggageDetails(code) {
		this.affiliateService.getBaggageDetails(code).subscribe(res => {
			this.baggageArr = res;
			console.log('baggage data', this.baggageArr)
		})
	}

	bindGSTInfo(val) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.postService.getMethod(`${environment.adminUrl}/common/suggest/gstDetails/${val}`).subscribe(res => {
				let data = JSON.parse(res['_body']);
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.name == el.name) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.gstArr = uniqueArr;
			})
		}
	}
	bindTravellerGstName(e) {
		this.travellerForm.controls.corporateName.setValue(e.target.value);
	}
	setGstDetails(values) {
		this.travellerForm.controls.corporateName.setValue(values.name);
		this.travellerForm.controls.corporateGstNumber.setValue(values.gst_number);
		this.travellerForm.controls.corporateMobile.setValue(values.mobile);
		this.travellerForm.controls.corporateEmail.setValue(values.email);
	}

	baggageOnChange(el) {
		let filteredData = this.baggageArr.filter((v) => {
			if (el.value == v.id) {
				return v;
			}
		})
		if (filteredData.length > 0) {
			this.selectedBaggage = filteredData;
			this.showBaggage = true;
			this.totalFare = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
			this.total = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
		}
		else {
			this.selectedBaggage = [];
			this.showBaggage = false;
			this.totalFare = this.selectedFares[0].total;
			this.total = this.selectedFares[0].total;
		}
		console.log(filteredData)
	}

	getSSR(requestData, isReturn) {
		let fullArr: any = [];
		let hasFreeMeal = false
		let hasFreeBaggage = false
		this.commonService.getmeal('ssrAvailability', requestData).subscribe(res => {
			fullArr = res;
			let legs = [];
			this.airlineCode
			if (res != null) {
				// this.ssrAvailability = Object.values(fullArr.legs);
				for (let i = 0; i < fullArr['legs'].length; i++) {
					const meals = fullArr["legs"][i].meals ?  Object.values(fullArr["legs"][i].meals) : [];
					let hasFreeMealPerLeg = false
					for (let j = 0; j < meals.length; j++) {
						const element = meals[j];
						element['status'] = 'notselected'
						const el = element['fare']
						if (el.hasOwnProperty("base")) {
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}
						if(element['totalFare'] == 0) {
							hasFreeMeal = true
							hasFreeMealPerLeg = true
							this.warningMessage = 'Free Meal available.'
						}
					}
					if(hasFreeMealPerLeg){
						isReturn ? this.totalFreeMealPerLegInBound++ : this.totalFreeMealPerLegOutBound++
					}
					const baggages = fullArr["legs"][i].baggages ?  Object.values(fullArr["legs"][i].baggages) : [];
					for (let j = 0; j < baggages.length; j++) {
						const element = baggages[j];
						element['status'] = 'notselected'
						const el = element['fare']

						if (el.hasOwnProperty("base")) {
							// element['totalFare'] = Number(el.base) + Number(el.tax);
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}
						if(element['totalFare'] == 0) {
							hasFreeBaggage = true;
							this.warningMessage = 'Free Baggage available.'
							if(hasFreeMeal) {
								this.warningMessage = 'Free Meal & Baggage available.'
							}
						}
					}

					const ancillaries = fullArr["legs"][i].ancillaries ?  Object.values(fullArr["legs"][i].ancillaries) : [];
					for (let j = 0; j < ancillaries.length; j++) {
						var element = ancillaries[j];
						element["status"] = "notselected";
						var el = element["fare"];

						if ( el && el.hasOwnProperty("base")) {
						element["totalFare"] = Number(el.base);
						} else {
						element["fare"] = {
							base : 0,
							tax : 0
						}
						element["totalFare"] = 0;
						}
					}


					let wheelChairs:any = []
					if(fullArr["legs"][i].wheelChairs){
					wheelChairs = Object.values(fullArr["legs"][i].wheelChairs);
					for (let j = 0; j < wheelChairs.length; j++) {
						const element = wheelChairs[j];
						element["status"] = "notselected";
						const el = element["fare"];

						if (el.hasOwnProperty("base")) {
						element["totalFare"] = Number(el.base);
						} else {
						element["totalFare"] = 0;
						}
					
						 }
					}
					let baggageAfterSorting = baggages.sort(function (a, b) {
						return a['totalFare'] - b['totalFare'];
					})
					let obj = {};
					obj["meals"] = meals;
					obj["baggages"] = baggageAfterSorting;
					obj["wheelChairs"] = wheelChairs
					obj["ancillaries"] = ancillaries;
					obj["arrival"] = fullArr['legs'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = requestData.travellers.length;
					obj["flightInfo"] = requestData
					let tempTravellerArr = [];
					for (let k = 0; k < requestData.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, requestData.travellers[k]);
						tempTravellerArr.push(tempTraveller);
					}
					obj["travellers"] = tempTravellerArr;
					obj["departure"] = fullArr['legs'][i].departure;
					obj["flightCode"] = fullArr['legs'][i].airline;
					obj["flightNo"] = fullArr['legs'][i].flightNo;
					legs.push(obj);
				}
			}

			if (isReturn) {
				this.InBoundSSRAvailability = legs;
				this.inBoundHasFreeMeal = hasFreeMeal
				this.inBoundHasFreeBaggage = hasFreeBaggage
			}
			else {
				this.ssrAvailability = legs;
				this.outBoundHasFreeMeal = hasFreeMeal
				this.outBoundHasFreeBaggage = hasFreeBaggage
			}


			if (this.ssrAvailability.length == 0 && this.InBoundSSRAvailability.length == 0) {
				this.showloadError = true
			}
			console.log('Meal Array', this.ssrAvailability);
		}, error => {
			if (isReturn) {
				this.InBoundSSRAvailability = [];
			}
			else {
				this.ssrAvailability = [];
			}
			if (this.ssrAvailability.length == 0 && this.InBoundSSRAvailability.length == 0) {
				this.showloadError = true
			}
			console.log('Fetch Error', error);
		})

		this.commonService.getSeat('seatMap', requestData).subscribe(res => {
			let seatMap = res;
			let legs = [];
			for (let i = 0; i < seatMap['leg'].length; i++) {
				if (seatMap['leg'][i].seats.rows != null) {
					const values = Object.values(seatMap['leg'][i].seats.rows)
					for (let j = 0; j < values.length; j++) {
						const element = values[j];
						let charges = Object.values(element)
						for (let k = 0; k < charges.length; k++) {
							const ch = charges[k]
							ch['totalFare'] = 0;
							ch['status'] = 'notselected'
							const el = ch.charges
							if (el.hasOwnProperty("base")) {
								ch['totalFare'] = Number(el.base);
							}
							else {
								ch['totalFare'] = 0;
							}
						}
					}
					let obj = {};
					obj["keys"] = Object.keys(seatMap['leg'][i].seats.rows);
					obj["values"] = values;
					obj['cabinInfo'] = seatMap['leg'][i].seats.cabinInfo ? seatMap['leg'][i].seats.cabinInfo : null
					obj["arrival"] = seatMap['leg'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = requestData.travellers.length;
					let tempTravellerArr = [];
					let associateTravellerInfoMap = new Map();
					for (let k = 0; k < requestData.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, requestData.travellers[k]);
						tempTravellerArr.push(tempTraveller);
						if (this.travellerForm.controls.passengerList['controls'][k].controls.passengerType.value == "Infant") {
							associateTravellerInfoMap.set(this.travellerForm.controls.passengerList['controls'][k].controls.associateInfo.value, "")
						}
					}
					obj['associateTravellerInfoMap'] = associateTravellerInfoMap;
					obj["travellers"] = tempTravellerArr;
					obj["flightInfo"] = requestData
					obj["departure"] = seatMap['leg'][i].departure;
					obj["flightCode"] = seatMap['leg'][i].flightCode;
					obj["flightNo"] = seatMap['leg'][i].flightNo;
					legs.push(obj);
				}
			}
			if (isReturn) {
				this.InBoundSeatMapArr = legs;
			}
			else {
				this.seatMapArr = legs;
			}
			if(this.seatMapArr.length==0) {
				this.showseatloadError=true;
				this.showseatloadErrorMessage = 'Unable To Fetch Seats Data'
			}

			console.log('Seat Map', this.seatMapArr);
		}, error => {
			this.showseatloadError = true
			this.showseatloadErrorMessage =  error.error ? error.error : 'Unable To Fetch Seats Data'
			console.log('Fetch Error', error);

		})
	}
	setGST(values) {
		if (values != null && values != undefined) {
			this.setGstDetails(values);
			setTimeout(() => {
				this.closeactiveModal();
			}, 100)
		}
	}
	private closeactiveModal(): void {
		this.closeactiveBtn.nativeElement.click();
	}
	openSearch() {
		let searchId = localStorage.searchId + "_RETURN_" + localStorage.returnSearchId + "_RETURN_" + localStorage.specialReturnSearchId
		// window.location("/#/pages/flight;searchId="+searchId);
		this.router.navigate(['/pages/flight', { searchId }]);
	}

	checkSpicemaxSeat(priceType, seatMapArr) {
		if (priceType == "SPICEMAX") {
			if (seatMapArr == undefined) {
				return false;
			}
			else if (seatMapArr[0].seats.length == 0) {

				return false;

			}
			else {
				this.warningMessage = "";
				return true;
			}
		}
		else {
			this.warningMessage = "";
			return true;
		}
	}
	setPaymentMethod(paymentMethod, checkNetPayable, direction) {
		this.selectedPaymentMethod[direction] = paymentMethod
		console.log('Payment method : ', this.selectedPaymentMethod)
		if (checkNetPayable == true && direction == 'inbound') {
			this.calculateNetPayable(true, false)
		} else if (checkNetPayable == true && direction == 'outbound') {
			this.calculateNetPayable(false, false)
		}
	}

	doOnlinePayment() {
		this.active = true
		let paymentMethod = ""
		localStorage.setItem('selectedFlightDirectionForPayment', this.selectedFlightDirectionForPayment)
		if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
			paymentMethod = "OPTCRDC"
		}
		else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'netbanking') {
			paymentMethod = "netbanking"
		} else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'debit') {
			paymentMethod = "debit"
		}
		let redirectUrl = `http://localhost:4200/#/pages/twoway-traveller`
		if (environment.name != 'default') {
			redirectUrl = `${environment.baseUrl}/#/pages/twoway-traveller`
		}
		// let amount_charged = Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100;
		let limitType = 'cash'
		if (!this.isDiAccount && !this.isLCC) {
			limitType = 'credit'
		}
		let amount = 1
		if (environment.name == 'prod') {
			amount = this.onlinePaymentAmount[this.selectedFlightDirectionForPayment]
		}
		if (this.affiliateDetails != null && this.affiliateDetails != undefined) {
			let obj = {
				"affiliateCode": this.affiliateDetails.xlAccountCode,
				"affiliateId": this.affiliateDetails.affiliateId,
				"redirectUrl": redirectUrl,
				"amount": amount,
				"order_id": "PT500069",
				"mobile": this.affiliateDetails.mobile,
				"email": this.affiliateDetails.primaryEmail,
				"payment_mode": paymentMethod,
				"limit_type": limitType,
				"original_amount": this.originalNetPayable[this.selectedFlightDirectionForPayment]
			}
			if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
				this.travellerService.doHDFCPayment(obj)
			} else {
				this.travellerService.doFTCASHPayment(obj)
			}
		}
	}

	showError(type,message,detail){
		this.messageList['status'] = true
		let obj ={
			warningType : type,
			message : message,
			detail : detail
		}
		this.messageList['messageList'].push(obj)
		this.notificationService.showMessage(this.messageList)
	}

	travellerFareSlider = {
		loop: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		nav: true,
		autoplayHoverPause:true,
		autoplay: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
		  0: {
			items: 1
		  },
		  480: {
			items: 1
		  },
		  1400: {
			items: 1
		  }
		},
	  }

	  selectAddonTab(tabName){
		  this.activeAddonTab = tabName;
	  }
	
	  toggleAddonSideBar(){
		this.toggleAddon = !this.toggleAddon
		this.ngRedux.dispatch(ToggleAddonSummary(this.toggleAddon));
	  }

	  initializeSelectedSsr(requestPayload) {
		let payLoad = {
		  travellers : requestPayload.travellers,
		  segments : requestPayload.segments
		}
			this.ngRedux.dispatch(InitializeSelectedSSR(payLoad));
		}
	getInsuranceData(searchRequest){
		let noOfAdult = searchRequest.noOfAdults;
		let	noOfChild = searchRequest.noOfChild;
		let noOfInfant = searchRequest.noOfInfants;
		let insuredPersons = Number(noOfAdult) + Number(noOfChild) + Number(noOfInfant);
		let flight_direction = searchRequest.roundTrip ? 'round_trip' : 'one_way'
		let originAirport = searchRequest.originDetails.code
		let destinationAirport = searchRequest.destinationDetails.code
		this.commonService.getInsurancePackage(`insurance/digit/v1.0/package/?no_of_insured_person=${insuredPersons}&flight_direction=${flight_direction}&origin=${originAirport}&destination=${destinationAirport}`).subscribe((res : any)=>{
			this.insuranceAmountPerPerson = 0
			this.totalInsuranceAmount = 0
			if(res && res.covers){
			this.insurancePackageData = res
			}
		})
	}

	toggleInsuranceCoverListView(){
		this.isInsuranceCoverExpanded = !this.isInsuranceCoverExpanded
		if(this.isInsuranceCoverExpanded){
		  this.insuranceCoverViewCount = this.insurancePackageData.covers.length
		}else{
		  this.insuranceCoverViewCount = 2
		}
	  }
	
	  toggleInsuranceSelection(isInsuranceSelected){
		this.isInsuranceSelected = isInsuranceSelected
		if(isInsuranceSelected){
		  this.insuranceAmountPerPerson = this.insurancePackageData.amount_per_person
		  this.totalInsuranceAmount = this.insurancePackageData.amount_per_person*this.totalPassengerCount
		}else{
		  this.insuranceAmountPerPerson = 0
		  this.totalInsuranceAmount = 0
		}
		this.calculateNetPayable(true,false)
	  }
	  
	  selectInfantAssociate(parent,currentIndex){
		let arr = []
		this.selectedInfantAssociate.forEach(value=> {
		  arr.push(value)
		})
		if(arr.indexOf(currentIndex) > -1){
		  this.selectedInfantAssociate.delete(arr.indexOf(currentIndex))
		}
		this.selectedInfantAssociate.set(parent,currentIndex)
	  }
	
	checkHoldButtonForGfs() {
		let hideHoldButton = false;
		let outboundSupplier = '', inboundSupplier = '';

		if(this.data && this.data.length>0) {
			outboundSupplier = this.data[0].fares[0].supplierInfo && this.data[0].fares[0].supplierInfo.SUPPLIER ? this.data[0].fares[0].supplierInfo.SUPPLIER : '';
		}
		if(this.InboundData && this.InboundData.length>0) {
			inboundSupplier = this.InboundData[0].fares[0].supplierInfo && this.InboundData[0].fares[0].supplierInfo.SUPPLIER ? this.InboundData[0].fares[0].supplierInfo.SUPPLIER : '';
		}

		// told by rajesh to hide in case of gfs inbound outbound both;

		if(inboundSupplier == 'gfs' || outboundSupplier == 'gfs') {
			hideHoldButton = true;
		}
		return hideHoldButton;
	}  
}
