import { Injectable } from '@angular/core';
import { CookiesService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})

export class FingerprintService {
  private fp: any;
  private fingerprintData: any;

  constructor(
    private cookiesService: CookiesService
  ) {
    this.fp = window['fp'] || null;
  }

  async getFingerprint() {
    let cookieData = this.cookiesService.getCookie('fingerprintData');
    if(!this.fp) this.fp = window['fp'] || null;
    if(cookieData) {
        cookieData = JSON.parse(cookieData);
        this.fingerprintData = cookieData;
    } else {
      this.fingerprintData = await this.fp.get();
    }
    return this.fingerprintData;
  }

}
