import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import * as moment from 'moment-timezone';
import { PostService } from 'src/app/services/post.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-pnr-retrieve-booking-summary',
	templateUrl: './pnr-retrieve-booking-summary.component.html',
	styleUrls: ['./pnr-retrieve-booking-summary.component.scss']
})
export class PnrRetrieveBookingSummaryComponent implements OnInit {
	gstAmount: number;
	tdsAmount: number;
	GSTonCancellation: number;
	addOnMarkupForm: any;
	fb: any;
	searchId: any;
	bookingData: any;
	ismultiLeg: boolean;
	isTicketed: boolean;
	bookingArr: any = [];
	valid: boolean;
	travellerFareArray = [];
	totalPublishedFare = 0;
	resIssuanceCharges = 0;
	resIssuanceFareDiffernceCharges = 0;
	totalNetPayable = 0;
	totalNetPayableRoundOff = 0;

	totalCommission = 0;
	totalTds = 0;
	totalServiceCharges = 0;
	totalGstOnServiceCharges = 0;
	totalAffiliateMarkup = 0;
	totalAffilateAdonMarkup = 0;
	totalAirlineCancellationCharges = 0;
	totalMyCancellationCharges = 0;
	totalGstOncancellationCharges = 0;
	total_insured_person_amount_sum = 0
	totalRefundAmount = 0;
	totalTravelledFare = 0;
	totalRefundedCommission = 0;
	totalRefundedTds = 0;
	creditLimitResponse;
	invalidCredit: boolean = false;
	showHold = false;
	isLCC: boolean = false;
	subscription: any;
	limitType: any;
	creditLimit: any;
	bookedWithAffiliateCreds: boolean;
	newBookingObj: any;
	affiliateDetails: any;
	seatAmount: number = 0;
	mealAmount: number = 0;
	baggageAmount: number = 0;
	additionalBaggageAmount: number = 0;
	ancillaryAmount: number = 0;
	addOnsSelected: boolean = false;
	totalAddOnPrice: number;
	totalUpdatedAmount: any;
	bookingHistoryArr = []
	mealCharges: number = 0;
	seatCharges: number = 0;
	baggageCharges: number = 0;
	additionalBaggageCharges: number = 0;
	ancillaryCharges: number = 0;
	pnrRetrieveData: any;
	active: boolean = false;
	selectedFlightData: any = [];
	selectedFlightDataIndex: any;
	showError: boolean = false;
	errorMessage: string = '';
	retrievalErrorMessage: any;
	upsellFaresLoading: boolean = false;
	@ViewChild("showPriceChange") showPriceChange: ElementRef;
	priceChangedAmountObj = {
		"oldFare": 0,
		"newFare": 0,
	}

	constructor(private route: ActivatedRoute,
		private router: Router,
		private commonService: CommonService,
		private bookingRequestService: BookingRequestService,
		private travellerService: TravellerService,
		private bookingService: BookingService,
		private postService: PostService) { }

	ngOnInit() {
		this.gstAmount = 0
		this.tdsAmount = 0
		this.GSTonCancellation = 0
		// this.addOnMarkupForm = this.fb.group({
		// 	'showAs': ['', Validators.required],
		// 	'amount': ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
		// })
		this.affiliateDetails = JSON.parse(localStorage.user_details)

		
		this.subscription = this.bookingRequestService.getLimit.subscribe(res=>{
			this.creditLimit = res['creditLimit'];
			this.invalidCredit = res['invalidCredit'];
			this.limitType = res['type'];
		})
		this.getData()

			this.isVisible = true; 
	  
		setTimeout(() => {
			this.isVisible = false;
		}, 20000); 
	}

	isVisible: boolean = false;

	// isStripVisible = false;
	// scrollHeightThreshold = 0;

	// @HostListener('window:scroll', ['$event'])
	// onWindowScroll() {
	// 	const scrollPosition = window.scrollY;
	
	// 	if (this.bookingData && this.bookingData.is_domestic && scrollPosition >= this.scrollHeightThreshold && !this.isStripVisible) {
	// 		this.isStripVisible = true;
	
	// 		setTimeout(() => {
	// 			this.isStripVisible = false;
	// 		}, 1000);
	// 	}
	// }

	getData(){
		this.retrievalErrorMessage = '';
		this.active = true;
		this.searchId = this.route.snapshot.params['searchId'];
		
		let affId = JSON.parse(localStorage.getItem('user_details'))['affiliateId'];
		let loginUserDetails = JSON.parse(localStorage.user_details);
		
		let url = `${environment.pnr_retrieve}/amadeus/amadeus/v4.0/retrieveHold/${affId}/${this.searchId}`;

		if(loginUserDetails.emulated_by) {
			url = `${environment.pnr_retrieve}/amadeus/amadeus/v4.0/retrieveHoldV2/${affId}/${this.searchId}`;
		}
		let supplier = this.route.snapshot.params['supplier'];
		if(supplier == '1G') {
			url = `${environment.pnr_retrieve}/tuapi/uapi/v1.0/retrieve/${affId}/${this.searchId}`;
		}
		this.postService.getMethodWithHttpClient(url).subscribe(res => {
				let data = res['data']
				if(res['message'] && res['message'] == 'PriceChanged'){ 
					this.openPriceChangeModal();
					if(!res['oldData']) {
						this.retrievalErrorMessage = 'Fare has been changed, but old data is missing.';
						return;
					}
					this.calculateTotalFare(data[0], res['oldData'][0])
				}
				
				this.active = false;
				let loginUserDetails = JSON.parse(localStorage.user_details);
				data[0].affiliate_id = loginUserDetails.affiliateId;

				console.log('Booking Data : ',this.bookingData);
				
				data[0]['json_doc'].contactdetails = {
					"affiliateAddress": {
						"address1": loginUserDetails.address1,
						"address2": loginUserDetails.address2,
						"city": loginUserDetails.city_name,
						"companyName": loginUserDetails.companyName,
						"countryCode": "IN",
						"stateCode": loginUserDetails.state_name,
						"zip": loginUserDetails.pincode,
						"xl_code": loginUserDetails.xlAccountCode, 	
						"affiliate_id": loginUserDetails.affiliateId, 
						"affiliate_company_name": loginUserDetails.companyName,
						"booked_by_user_name": loginUserDetails.firstName + " "+loginUserDetails.lastName, 
						"affiliate_number": loginUserDetails.mobile
					},
					"email": loginUserDetails.primaryEmail,
					"phone": loginUserDetails.mobile
				}
				
				console.log('Booking Data : ',data[0]['json_doc']);

				data[0]['json_doc'].xl_code = loginUserDetails.xlAccountCode;
				data[0]['json_doc'].affiliate_id = loginUserDetails.affiliateId;
				data[0]['json_doc'].booked_by_user_id = loginUserDetails.userId;
				data[0]['json_doc'].booked_by_user_name = loginUserDetails.firstName + " "+loginUserDetails.lastName;
				
				data[0]['json_doc'].affiliate_company_name = loginUserDetails.companyName
				
				if(!data[0]['json_doc'].commission) {
					data[0]['json_doc'].commission = {
						"customer": {
							"iataType": "RB",
							"iataValue": "0.0",
							"iataAmount": "0.0",
							"plbType": "RB",
							"plbValue": "0.0",
							"plbAmount": "0.0",
							"cashbackType": "VL",
							"cashbackValue": "0.0",
							"cashbackAmount": "0.0",
							"supSrvCh": 0
						},
						"supplier": {
							"iataType": "RB",
							"iataValue": "0.0",
							"iataAmount": "0.0",
							"plbType": "RB",
							"plbValue": "0.0",
							"plbAmount": "0.0",
							"cashbackType":  "VL",
							"cashbackValue": "0.0",
							"cashbackAmount": "0.0",
							"supSrvCh": 0
						}
					}
				}
				
				this.bookingData = data[0]['json_doc'];
				if(loginUserDetails.emulated_by && loginUserDetails.emulated_by_name){
					this.bookingData.booked_by_user_name = loginUserDetails.emulated_by_name;
				}

				if (this.bookingData.segments[0].legs.length > 1) {
					this.ismultiLeg = true;
				}
				else {
					this.ismultiLeg = false;
				}
				this.bookedWithAffiliateCreds = false
				if(this.bookingData.hasOwnProperty('supplierInfo')) {
					if(this.bookingData['supplierInfo'].hasOwnProperty('isBookWithAffiliatesCreds')) {
						this.bookedWithAffiliateCreds = this.bookingData['supplierInfo']['isBookWithAffiliatesCreds']
					}
				}
				this.isTicketed = false;
				if (data[0]['json_doc'].pnr_status == "TICKETED") {
					this.isTicketed = true;
				}

				this.bookingArr = data
				
				this.newBookingObj = Object.assign({},this.bookingArr)
				let actualBookingRef = this.bookingArr[0].actual_booking_ref_no
				if (actualBookingRef == undefined) {
					actualBookingRef = this.searchId
				}
				this.selectBookingData(0);
				if(this.route.snapshot.params['supplier'] == '1A') {
					this.getUpsellData(data);
				}

		}, error => {
			console.log('Error in get booking : ', error)
			this.active = false;
			this.retrievalErrorMessage = error['error']['message'] ? error['error']['message'] : error.error;
			if(this.retrievalErrorMessage == 'PriceChanged'){
				this.openPriceChangeModal();
			}
		})
	}

	getUpsellData(data) {
		this.searchId = this.route.snapshot.params['searchId'];
		let loginUserDetails = JSON.parse(localStorage.user_details);
		
		let url = `amadeus/amadeus/v4.0/retrieveHold/upsell`;
		if(loginUserDetails.emulated_by) {
			url = `amadeus/amadeus/v4.0/retrieveHoldV2/upsell`;
		}
		let request = {
			data: data
		}
		this.upsellFaresLoading = true;
		this.postService.postMethodWithHttpClient(`${environment.pnr_retrieve}/${url}`, request).subscribe(res => {
			let data = res['data']
			this.upsellFaresLoading = false;
			if(data && data.length > 0) {
				for(let item of data) {
					item['json_doc'].contactdetails = {
						"affiliateAddress": {
							"address1": loginUserDetails.address1,
							"address2": loginUserDetails.address2,
							"city": loginUserDetails.city_name,
							"companyName": loginUserDetails.companyName,
							"countryCode": "IN",
							"stateCode": loginUserDetails.state_name,
							"zip": loginUserDetails.pincode,
							"xl_code": loginUserDetails.xlAccountCode, 	
							"affiliate_id": loginUserDetails.affiliateId, 
							"affiliate_company_name": loginUserDetails.companyName,
							"booked_by_user_name": loginUserDetails.firstName + " "+loginUserDetails.lastName, 
							"affiliate_number": loginUserDetails.mobile
						}
					}

					item['json_doc'].xl_code = loginUserDetails.xlAccountCode;
					item['json_doc'].affiliate_id = loginUserDetails.affiliateId;
					item['json_doc'].booked_by_user_id = loginUserDetails.userId;
					item['json_doc'].booked_by_user_name = loginUserDetails.firstName + " "+loginUserDetails.lastName;
					item['json_doc'].affiliate_company_name = loginUserDetails.companyName

					if(!item['json_doc'].commission) {
						item['json_doc'].commission = {
							"customer": {
								"iataType": "RB",
								"iataValue": "0.0",
								"iataAmount": "0.0",
								"plbType": "RB",
								"plbValue": "0.0",
								"plbAmount": "0.0",
								"cashbackType": "VL",
								"cashbackValue": "0.0",
								"cashbackAmount": "0.0",
								"supSrvCh": 0
							},
							"supplier": {
								"iataType": "RB",
								"iataValue": "0.0",
								"iataAmount": "0.0",
								"plbType": "RB",
								"plbValue": "0.0",
								"plbAmount": "0.0",
								"cashbackType":  "VL",
								"cashbackValue": "0.0",
								"cashbackAmount": "0.0",
								"supSrvCh": 0
							}
						}
					}

					this.bookingArr.push(item)
				}
			}
			for(let bookingIndex in this.bookingArr) {
				this.bindLocalTravellerFares(bookingIndex);
			}

			this.bookingArr = this.bookingArr.sort((a, b) => {
				return a.totalPublishedFare - b.totalPublishedFare
			})

			this.selectBookingData(0);
			
		}, error => {
			console.log('Error in get booking : ', error)
			this.upsellFaresLoading = false;
		});
	}

	async getPnrData(){
			this.pnrRetrieveData = await this.postService.retrievePNR(`${environment.baseUrl}/edge/amadeus/amadeus/v4.0/retrieve/default/${this.bookingArr[0].json_doc.gds_pnr}`)
			console.log('PNR_DATA : ',this.pnrRetrieveData)
			
			if(this.pnrRetrieveData && this.pnrRetrieveData.ssrResponse){
				this.bindTravellers(this.bookingArr[0].json_doc.travellers,this.pnrRetrieveData.ssrResponse)
			}
	}
	bindSSR(request, pax,isFailedSSR) {
		let ssrArr = [];
		for (let i = 0; i < request.ssrs.length; i++) {
			let obj = {
				"origin": request.ssrs[i].supplierInfo['departureStation'],
				"destination": request.ssrs[i].supplierInfo['arrivalStation'],
				"flight": request.ssrs[i].supplierInfo['flightNo'],
				"leg_index": i,
				"seats": this.getPaxNumber(request.ssrs[i].seats, pax, 'seats',isFailedSSR),
				"meals": this.getPaxNumber(request.ssrs[i].meals, pax, 'meals',isFailedSSR),
				"baggages": this.getPaxNumber(request.ssrs[i].baggages, pax, 'baggages',isFailedSSR),
				"ancillaries": this.getPaxNumber(request.ssrs[i].ancillaries, pax, 'ancillaries',isFailedSSR),
				"additionalBaggages": this.getPaxNumber(request.ssrs[i].additionalBaggages, pax, 'additionalBaggages',isFailedSSR),
				"wheelChairs":this.getPaxNumber(request.ssrs[i].wheelChairs, pax, 'wheelChairs',isFailedSSR)
			}
			ssrArr.push(obj);
		}
		return ssrArr;
	}
	
	getPaxNumber(item, pax, type,isFailedSSR) {
		if (item != null) {
			for (let i = 0; i < item.length; i++) {
				if (item[i].travellerNumber == pax) {
					let code = item[i].code
					if (type == 'seats') {
						code = item[i].number
					}
					let price = item[i].price ? item[i].price : 0
					if(!code && item[i].items){
						let codes = []
						for (let iterator of item[i].items) {
							price += Number(iterator.totalFare)
							codes.push(iterator.code)
							iterator.status = iterator.status ? iterator.status : isFailedSSR ? 'FAILED' : 'SUCCESS'
							if(iterator.status == 'notselected'){ // DIRTY CHECK
								iterator.status = 'SUCCESS'
							}
						}
						code = codes.join(', ')
	
					}
					
					let itemObj = {
						'totalFare': price,
						'code': code,
						'name': item[i].name
					}
					let obj = {
						"code": item[i].items ? item[i].items : itemObj,
						"name": code,
						"fare": {
							"base": price,
							"tax": 0
						},
						"status" : item[i].status ? item[i].status : isFailedSSR ? 'FAILED' : 'SUCCESS'
					}
					return obj;
	
				}
			}
		}
		return null;

	}
	
	
	bindTravellers(travellers,ssrResponse) {
		let hasChanges = false
		for (let traveller of travellers) {
			let travellernumber = travellers.indexOf(traveller)
			let ssrs = this.bindSSR(ssrResponse, travellernumber,ssrResponse.status == 'SUCCESS')

			for(let ssr of ssrs) { 
				let ssrNumber = ssrs.indexOf(ssr)
				let travellerSSr = traveller.ssr[ssrNumber]   
				let ssrTypes = ['meals', 'baggages', 'seats']
	
				for(let ssrT of ssrTypes) {
					if(ssr[ssrT] != null) {
						if(ssr[ssrT].code && ssr[ssrT].code.code && travellerSSr[ssrT].code && travellerSSr[ssrT].code.code) {
							if(ssr[ssrT].code.code == travellerSSr[ssrT].code.code) {
								if(ssr[ssrT]['status'] && travellerSSr[ssrT]['status']) {
									if(ssr[ssrT]['status'] != travellerSSr[ssrT]['status']) {
										hasChanges = true
										travellerSSr[ssrT]['status'] = ssr[ssrT]['status']
									}
								}
							} 
						}
					}
				}
			}
		}
	}

	async bindTravellerFares(selectedBookingIndex) {
		this.totalServiceCharges = 0
		this.totalGstOnServiceCharges = 0
		this.totalAffiliateMarkup = 0
		this.totalCommission = 0
		this.totalTds = 0	
		this.totalPublishedFare = 0;
		this.mealCharges = 0
		this.seatCharges = 0
		this.baggageCharges = 0
		this.additionalBaggageCharges = 0
		this.ancillaryCharges = 0
		this.totalAffilateAdonMarkup = 0
		this.resIssuanceCharges = 0
		this.resIssuanceFareDiffernceCharges = 0
		let json_doc = this.bookingArr[selectedBookingIndex].json_doc;
		this.total_insured_person_amount_sum = (json_doc.hasOwnProperty("insurance_policy") && json_doc.insurance_policy.status=='issued' && json_doc.insurance_policy.total_insured_person_amount_sum)?json_doc.insurance_policy.total_insured_person_amount_sum:0

		// this.totalCommission = this.getCommission(json_doc);
		// this.totalTds = this.getTDS(json_doc);
		let travellerFareMap = new Map();
		let travellerMap = new Map()
		for (let i = 0; i < json_doc.travellers.length; i++) {
			let keyName = 'fare'
			if(json_doc.travellers[i].hasOwnProperty('refunded_fare')) {
				keyName = 'refunded_fare'
			}
			if(travellerMap.has(json_doc.travellers[i].type)){
				let obj = travellerMap.get(json_doc.travellers[i].type);
				if(`${obj.first_name}_${obj.last_name}` == `${json_doc.travellers[i].first_name}_${json_doc.travellers[i].last_name}`){
					obj.count ++
				}
				travellerMap.set(json_doc.travellers[i].type,obj)
			}else{
				let obj = {
					count : 1,
					first_name : json_doc.travellers[i].first_name,
					last_name : json_doc.travellers[i].last_name
				}
				travellerMap.set(json_doc.travellers[i].type,obj)
			}
			if (travellerFareMap.has(json_doc.travellers[i].type)) {
				let obj = travellerFareMap.get(json_doc.travellers[i].type);
				obj.count ++
				travellerFareMap.set(json_doc.travellers[i].type, obj);
			}
			else{
				let obj = {
					"type" : json_doc.travellers[i].type,
					"count" : 1,
					"tax" : this.getOT(json_doc.travellers[i],false,keyName)-Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["K3"])+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_MARKUP"]))+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_ADD_ON_MARKUP"]))),
					"base" : Number(this.checkUndefined(json_doc.travellers[i][keyName].base)),
					"otherTax" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_MARKUP"]))+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_ADD_ON_MARKUP"])),
					"total" : (Number(this.getOT(json_doc.travellers[i],false,keyName)) + Number(this.checkUndefined(json_doc.travellers[i][keyName].base)) ),
					"re_issuance_charges" : Number(json_doc.travellers[i]['fare'].reissuance_charges ? json_doc.travellers[i]['fare'].reissuance_charges : 0),
					"offerPrice" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["NET_PAYABLE"])),
					"COMMISSION" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["COMMISSION"])),
					"TDS" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["TDS"])),
					"ADMIN_SERVICE_CHARGE" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["ADMIN_SERVICE_CHARGE"])),
					"GST" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["GST"])),
					"K3":Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["K3"]))
				}
				travellerFareMap.set(json_doc.travellers[i].type, obj);
			
			}
			this.totalTds +=  Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["TDS"]))
			this.totalCommission += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["COMMISSION"]))
			this.totalServiceCharges += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["ADMIN_SERVICE_CHARGE"]));
			this.totalGstOnServiceCharges += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["GST"]));
			this.totalAffiliateMarkup += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["AFFILIATE_MARKUP"]));
			this.totalAffilateAdonMarkup+=Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["AFFILIATE_ADD_ON_MARKUP"]))	
			this.totalPublishedFare += (Number(this.getOT(json_doc.travellers[i],true,keyName)) + Number(this.checkUndefined(json_doc.travellers[i][keyName].base)) - Number(json_doc.travellers[i].fare.reissuance_charges ? json_doc.travellers[i].fare.reissuance_charges : 0));
			this.resIssuanceCharges += (Number(json_doc.travellers[i].fare.reissuance_charges ? json_doc.travellers[i].fare.reissuance_charges : 0))
			this.resIssuanceFareDiffernceCharges += (Number(json_doc.travellers[i].fare.reissue_fare_difference ? json_doc.travellers[i].fare.reissue_fare_difference : 0))

			if(json_doc.travellers[i].hasOwnProperty('refunded_fare')) {
				// this.totalRefundAmount += json_doc.travellers[i].refunded_fare.refunded_total;
				this.calculateRefundedAmount(json_doc.travellers[i].refunded_fare);
			}
			this.mealCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'meal') 
			this.seatCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'seat') 
			this.baggageCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'baggage') 
			this.additionalBaggageCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'additionalBaggage') 
			this.ancillaryCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'ancillary') 
		
		}
		// this.totalRefundAmount = this.totalRefundAmount - this.totalServiceCharges - this.totalGstOnServiceCharges;
		this.travellerFareArray = Array.from( travellerFareMap.values());
		this.totalPublishedFare+=this.total_insured_person_amount_sum
		this.totalNetPayable = this.totalPublishedFare  + this.totalTds - this.totalCommission - this.totalAffiliateMarkup-this.totalAffilateAdonMarkup;
		// this.totalNetPayable = this.totalPublishedFare  + this.totalTds - this.totalCommission;
		this.totalTravelledFare = this.totalNetPayable - this.totalRefundAmount;
		this.totalNetPayableRoundOff = this.totalNetPayable-Math.round(this.totalNetPayable)
		let isLcc = this.travellerService.checkLCC(this.bookingData['segments'][0]['legs'][0]['marketing_airline_code']);
		this.isLCC = isLcc
		this.bookingRequestService.getCreditLimit(isLcc, this.totalNetPayable, JSON.parse(localStorage.user_details)['affiliateId'] , 'outbound');
		// const lThis = this;
		// setTimeout(()=>{
		// 	console.log("credit limit " + lThis.creditLimitResponse)
		// 	lThis.isActiveLimit = !lThis.creditLimitResponse['invalidCredit'];
		// 	console.log(" isActiveLimit "+ lThis.isActiveLimit)
		// },3000)
		// this.isActiveLimit = !response['invalidCredit'];
		
		this.bookingArr[selectedBookingIndex]['totalNetPayable'] = this.totalNetPayable;
		this.bookingArr[selectedBookingIndex]['totalPublishedFare'] = this.totalPublishedFare;
		this.bookingArr[selectedBookingIndex]['totalServiceCharges'] = this.totalServiceCharges;


	}

	async bindLocalTravellerFares(selectedBookingIndex) {
		let totalServiceCharges = 0
		let totalGstOnServiceCharges = 0
		let totalAffiliateMarkup = 0
		let totalCommission = 0
		let totalTds = 0	
		let totalPublishedFare = 0;
		let mealCharges = 0
		let seatCharges = 0
		let baggageCharges = 0
		let additionalBaggageCharges = 0
		let ancillaryCharges = 0
		let totalAffilateAdonMarkup = 0
		let resIssuanceCharges = 0
		let resIssuanceFareDiffernceCharges = 0
		let json_doc = this.bookingArr[selectedBookingIndex].json_doc;
		let total_insured_person_amount_sum = (json_doc.hasOwnProperty("insurance_policy") && json_doc.insurance_policy.status=='issued' && json_doc.insurance_policy.total_insured_person_amount_sum)?json_doc.insurance_policy.total_insured_person_amount_sum:0

		let travellerFareMap = new Map();
		let travellerMap = new Map()
		for (let i = 0; i < json_doc.travellers.length; i++) {
			let keyName = 'fare'
			if(json_doc.travellers[i].hasOwnProperty('refunded_fare')) {
				keyName = 'refunded_fare'
			}
			if(travellerMap.has(json_doc.travellers[i].type)){
				let obj = travellerMap.get(json_doc.travellers[i].type);
				if(`${obj.first_name}_${obj.last_name}` == `${json_doc.travellers[i].first_name}_${json_doc.travellers[i].last_name}`){
					obj.count ++
				}
				travellerMap.set(json_doc.travellers[i].type,obj)
			}else{
				let obj = {
					count : 1,
					first_name : json_doc.travellers[i].first_name,
					last_name : json_doc.travellers[i].last_name
				}
				travellerMap.set(json_doc.travellers[i].type,obj)
			}
			if (travellerFareMap.has(json_doc.travellers[i].type)) {
				let obj = travellerFareMap.get(json_doc.travellers[i].type);
				obj.count ++
				travellerFareMap.set(json_doc.travellers[i].type, obj);
			}
			else{
				let obj = {
					"type" : json_doc.travellers[i].type,
					"count" : 1,
					"tax" : this.getOT(json_doc.travellers[i],false,keyName)-Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["K3"])+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_MARKUP"]))+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_ADD_ON_MARKUP"]))),
					"base" : Number(this.checkUndefined(json_doc.travellers[i][keyName].base)),
					"otherTax" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_MARKUP"]))+Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["AFFILIATE_ADD_ON_MARKUP"])),
					"total" : (Number(this.getOT(json_doc.travellers[i],false,keyName)) + Number(this.checkUndefined(json_doc.travellers[i][keyName].base)) ),
					"re_issuance_charges" : Number(json_doc.travellers[i]['fare'].reissuance_charges ? json_doc.travellers[i]['fare'].reissuance_charges : 0),
					"offerPrice" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["NET_PAYABLE"])),
					"COMMISSION" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["COMMISSION"])),
					"TDS" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["TDS"])),
					"ADMIN_SERVICE_CHARGE" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["ADMIN_SERVICE_CHARGE"])),
					"GST" : Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["GST"])),
					"K3":Number(this.checkUndefined(json_doc.travellers[i][keyName].taxes[0]["K3"]))
				}
				travellerFareMap.set(json_doc.travellers[i].type, obj);
			
			}
			totalTds +=  Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["TDS"]))
			totalCommission += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["COMMISSION"]))
			totalServiceCharges += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["ADMIN_SERVICE_CHARGE"]));
			totalGstOnServiceCharges += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["GST"]));
			totalAffiliateMarkup += Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["AFFILIATE_MARKUP"]));
			totalAffilateAdonMarkup+=Number(this.checkUndefined(json_doc.travellers[i].fare.taxes[0]["AFFILIATE_ADD_ON_MARKUP"]))	
			totalPublishedFare += (Number(this.getOT(json_doc.travellers[i],true,keyName)) + Number(this.checkUndefined(json_doc.travellers[i][keyName].base)) - Number(json_doc.travellers[i].fare.reissuance_charges ? json_doc.travellers[i].fare.reissuance_charges : 0));
			resIssuanceCharges += (Number(json_doc.travellers[i].fare.reissuance_charges ? json_doc.travellers[i].fare.reissuance_charges : 0))
			resIssuanceFareDiffernceCharges += (Number(json_doc.travellers[i].fare.reissue_fare_difference ? json_doc.travellers[i].fare.reissue_fare_difference : 0))

			if(json_doc.travellers[i].hasOwnProperty('refunded_fare')) {
				this.calculateRefundedAmount(json_doc.travellers[i].refunded_fare);
			}
			mealCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'meal') 
			seatCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'seat') 
			baggageCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'baggage') 
			additionalBaggageCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'additionalBaggage') 
			ancillaryCharges += this.getSSRPrice(json_doc.travellers[i].ssr,'ancillary') 
		
		}
		totalPublishedFare+=total_insured_person_amount_sum
		let totalNetPayable = totalPublishedFare  + totalTds - totalCommission - totalAffiliateMarkup-totalAffilateAdonMarkup;
		let isLcc = this.travellerService.checkLCC(this.bookingData['segments'][0]['legs'][0]['marketing_airline_code']);
		this.isLCC = isLcc
		
		this.bookingArr[selectedBookingIndex]['totalNetPayable'] = totalNetPayable;
		this.bookingArr[selectedBookingIndex]['totalPublishedFare'] = totalPublishedFare;
		this.bookingArr[selectedBookingIndex]['totalServiceCharges'] = totalServiceCharges;
	}


	calculateRefundedAmount(refundedFareObj) {
		
		let base = Number(refundedFareObj.base ? refundedFareObj.base : 0);
		let addAmount = 0;
		let lessAmount = 0;
		let keys = Object.keys(refundedFareObj.taxes[0]);
		let count = 0;
		const lThis = this;
		Object.values(refundedFareObj.taxes[0]).forEach(function (v) {
			if(keys[count] == "COMMISSION") {
				lessAmount += Number(v);
				lThis.totalRefundedCommission +=  Number(v);
			}
			if(keys[count] == "TDS") {
				addAmount +=  Number(v);
				lThis.totalRefundedTds +=  Number(v);
			}
			count++;
		})
		if(refundedFareObj.hasOwnProperty('cancellation_charges')) {
			let client_xxl_charges = refundedFareObj.cancellation_charges[0].client_xxl_charges ? refundedFareObj.cancellation_charges[0].client_xxl_charges : 0;
			let client_raf_charges = refundedFareObj.cancellation_charges[0].client_raf_charges ? refundedFareObj.cancellation_charges[0].client_raf_charges : 0;
			this.totalAirlineCancellationCharges += client_xxl_charges;
			this.totalMyCancellationCharges += client_raf_charges;
			lessAmount += client_raf_charges;
			lessAmount += client_xxl_charges;

		}
		this.totalGstOncancellationCharges += refundedFareObj.gst ?  refundedFareObj.gst : 0;
		lessAmount += refundedFareObj.gst ?  refundedFareObj.gst : 0;
		 
		this.totalRefundAmount += refundedFareObj.refunded_total - lessAmount + addAmount;
	}

	getOT(data,calculateSSR,keyName) {
		let otherTax = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element[keyName].taxes[0]);
		let count = 0;
		Object.values(element[keyName].taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "OTHER_TAX" && keys[count]!="REISSUANCE_FARE_DIFFERENCE") {
					otherTax += Number(v);
			}
			// if (keys[count] == "OTHER_TAX" || keys[count] == "OT" || keys[count] == "ADMIN_MARKUP") {
			// 	otherTax += Number(v);
			// }
			count++;
		})
		if (calculateSSR) {
			otherTax +=  this.getSSRPrice(element.ssr,'all');
		}
		return otherTax;
	}
	getSSRPrice(ssr,returnType) {
		let meal = 0;
		let baggage = 0;
		let additionalBaggage = 0;
		let ancillaryCharges = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null) {
					meal += Number(element.meals.code ? element.meals.code.totalFare : 0);
				}
				if (element.baggages != null) {
					baggage += Number(element.baggages.code ? element.baggages.code.totalFare : 0);
				}
				if (element.additionalBaggages != null) {
					additionalBaggage += Number(element.additionalBaggages.code ? element.additionalBaggages.code.totalFare : 0);
				}
				if (element.seats != null) {
					seat += Number(element.seats.code ? element.seats.code.totalFare : 0);
				}
				if(element.ancillaries != null) {
					if(Array.isArray(element.ancillaries.code)){
						for (const ancillary of element.ancillaries.code) {
							ancillaryCharges += Number(ancillary.code ? ancillary.totalFare : 0);
						}
					}else{
						ancillaryCharges += Number(element.ancillaries.code ? element.ancillaries.code.totalFare : 0);
					}
				}
			}
		}
		if (returnType == 'all') {
			return Number(meal) + Number(baggage) + Number(seat) + Number(ancillaryCharges) + Number(additionalBaggage)
		}else if (returnType == 'meal') {
			return Number(meal)
		}else if (returnType == 'seat') {
			return  Number(seat)
		}else if (returnType == 'baggage') {
			return Number(baggage)
		}else if (returnType == 'additionalBaggage') {
			return Number(additionalBaggage)
		}else if (returnType == 'ancillary') {
			return Number(ancillaryCharges)
		}else{
			return Number(meal) + Number(baggage) + Number(seat)
		}
	}

	checkUndefined(value) {
		return Object.is(value, undefined) ? 0 : value;
	}

	calculateAddOnPrice(ssr){
		this.addOnsSelected = ssr.showPrice ? ssr.showPrice : false
		this.seatAmount = ssr.seats ? ssr.seats  : 0;
		this.mealAmount = ssr.meals ? ssr.meals  : 0;
		this.baggageAmount = ssr.baggage ? ssr.baggage  : 0;
		this.additionalBaggageAmount = ssr.additionalBaggage ? ssr.additionalBaggage  : 0;
		this.ancillaryAmount = ssr.ancillary ? ssr.ancillary  : 0;
		this.totalAddOnPrice = this.seatAmount+this.mealAmount+this.baggageAmount+this.additionalBaggageAmount+this.ancillaryAmount
		this.totalUpdatedAmount = this.totalAddOnPrice + this.totalPublishedFare
		
	}

	getCommission(data){
		let totalComission = 0
		let totalPax = 0
		data.travellers.forEach(element => {
			if (element.type == 'ADT' || element.type == 'CHD') {
				totalPax++
			}
		});
		if (data.commission != undefined) {
			let commissionObj = data.commission
			totalComission = Number(commissionObj['customer'].cashbackAmount) + Number(commissionObj['customer'].iataAmount) + Number(commissionObj['customer'].plbAmount)
			totalComission = Number((totalComission - (totalComission * (18 / 100))).toFixed(2))

		}
		totalComission = (totalComission ? totalComission : 0);
		return totalComission
		
	}
	getTDS(data){
		let totalComission = 0
		let nettotalComission = 0
		let TDS = 0
		let totalPax = 0
		data.travellers.forEach(element => {
			if (element.type == 'ADT' || element.type == 'CHD') {
				totalPax++
			}
		});
		if (data.commission != undefined) {
			let commissionObj = data.commission
			totalComission = Number(commissionObj['customer'].cashbackAmount) + Number(commissionObj['customer'].iataAmount)+ Number(commissionObj['customer'].plbAmount)
			nettotalComission =  totalComission - (totalComission*(18/100))
			TDS =  Number((nettotalComission*(5/100)).toFixed(2))		
		}
		TDS = (TDS ? TDS : 0);
		return TDS;
	}

	selectBookingData(index) {
		this.selectedFlightDataIndex = index;
		this.selectedFlightData = [this.bookingArr[index]];
		this.bindTravellerFares(index);
	}
	
	async issueTicket() {
		this.active = true
		let updateObj = {
			"message":"success",
			"data": [this.bookingArr[this.selectedFlightDataIndex]]
		}

		let url = `amadeus/amadeus/v4.0/ticketHoldPnr`;
		let supplier = this.route.snapshot.params['supplier'];
		if(supplier == '1G') {
			url = `tuapi/uapi/v1.0/ticket`;
		}
		this.commonService.postMethod(`${environment.baseUrl}/edge/${url}`, updateObj).subscribe(res => {
		    let bookingRef = res['data'][0].json_doc.booking_ref;
			this.router.navigate(['pages/booking-summary-detail', {searchId: bookingRef}]);
			this.active = false;
		}, err => {
			this.showError = true;
			this.errorMessage = err.error.message ? err.error.message : err.error;
			this.active = false;
		})
	}

	refreshCreditLimit() {
		let isLcc = this.travellerService.checkLCC(this.bookingData['segments'][0]['legs'][0]['marketing_airline_code']);
		this.bookingRequestService.getCreditLimit(isLcc, this.totalNetPayable, JSON.parse(localStorage.user_details)['affiliateId'] , 'outbound');
    }

	private openPriceChangeModal(): void {
		this.showPriceChange.nativeElement.click();
	}

	private calculateTotalFare(newData, oldData) {
		for(let traveller of newData.json_doc.travellers) {
			this.priceChangedAmountObj.newFare += Number(traveller.fare.taxes[0].NET_PAYABLE)
		}
		for(let traveller of oldData.json_doc.travellers) {
			this.priceChangedAmountObj.oldFare += Number(traveller.fare.taxes[0].NET_PAYABLE)
		}
	}
	
}
